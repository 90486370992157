import React, { useState } from 'react';
import { TextInput, BooleanInput, required } from 'react-admin';
import { Grid } from '@material-ui/core';

const apiKeyValidation = [required()];

export const APIConfigs: React.FC = () => {
  return (
    <>
      <h3>API configs:</h3>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <TextInput
            source="api_buying_config.api_key"
            defaultValue={null}
            label="Api Key"
            validate={apiKeyValidation}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <BooleanInput
            source="api_buying_config.is_reg_enabled"
            defaultValue={false}
            label="Reg Enabled"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            source="api_buying_config.extra_reg"
            defaultValue={null}
            label="Success extra"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <BooleanInput
            source="api_buying_config.is_visit_enabled"
            defaultValue={false}
            label="Visit Enabled"
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput
            source="api_buying_config.extra_visit"
            defaultValue={null}
            label="Success visit extra"
          />
        </Grid>
      </Grid>
    </>
  );
};
