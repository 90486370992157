import { httpClient } from 'httpClient';
import { Package } from 'types/packages/packages';

interface ModerationCountersResponse {
  photo_moderation_counter: number;
  bot_photo_moderation_counter: number;
  video_moderation_counter: number;
}

export const Api = {
  fetchModerationCounters: async () => {
    const { data } = await httpClient.get<ModerationCountersResponse>(
      `/moderation-count`,
    );

    return data;
  },
  fetchAllPackages: async () => {
    const { data } = await httpClient.get<Package[]>(`/packages`);

    return data;
  },
};
