export enum Direction {
  Landing = 'landing',
  AutoReg = 'auto_reg',
  Api = 'api',
}

export enum Hosts {
  Hookupers = 'hookupers.com',
  BestHookuper = 'besthookuper.com',
  HookupParadise = 'hookupparadise.com',
  MePlusYou = 'meplusyou.club',
  LocalsDate = 'locals-date.com',
  LustStation = 'lust-station.com',
  LocalsJoy = 'localsjoy.com',
  DatesMe = 'dates-me.com',
}

export const directionChoices = [
  { id: null, name: '< Choose one >' },
  { id: Direction.Landing, name: 'Landing' },
  { id: Direction.AutoReg, name: 'Auto Reg' },
  { id: Direction.Api, name: 'API' },
];

export const hostsChoices = [
  { id: null, name: '< Choose one >' },
  { id: Hosts.Hookupers, name: Hosts.Hookupers },
  { id: Hosts.BestHookuper, name: Hosts.BestHookuper },
  { id: Hosts.HookupParadise, name: Hosts.HookupParadise },
  { id: Hosts.MePlusYou, name: Hosts.MePlusYou },
  { id: Hosts.LocalsDate, name: Hosts.LocalsDate },
  { id: Hosts.LustStation, name: Hosts.LustStation },
  { id: Hosts.LocalsJoy, name: Hosts.LocalsJoy },
  { id: Hosts.DatesMe, name: Hosts.DatesMe },
];
