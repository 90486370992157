import React, { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import MailDomainsForm from './MailDomainsForm';

const MailDomainsEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title="Edit legal info" {...props}>
      <MailDomainsForm />
    </Edit>
  );
};

export default MailDomainsEdit;
