import React, { FC } from 'react';
import {
  NumberInput,
  ResourceComponentProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

const MailDomainsForm: FC<ResourceComponentProps> = ({ ...props }) => {
  return (
    <SimpleForm redirect="list" {...props}>
      <NumberInput source="id" validate={required()} />
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  );
};

export default MailDomainsForm;
