import React, { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { UserPhoto } from '../model-types';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    item: {
      margin: '0 10px 10px 0',
    },
    img: {
      height: '300px',
      objectFit: 'contain',
      cursor: 'zoom-in',
    },
  }),
);

export type ImageGridListProps = {
  tiles: UserPhoto[];
};
const ImageGridList = ({ tiles }: ImageGridListProps) => {
  const classes = useStyles();

  const [index, setIndex] = useState(-1);

  const currentImage = tiles[index];
  const nextIndex = (index + 1) % tiles.length;
  const nextImage = tiles[nextIndex] || currentImage;
  const prevIndex = (index + tiles.length - 1) % tiles.length;
  const prevImage = tiles[prevIndex] || currentImage;

  const handleClick = (index: number) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {!!currentImage && (
          <Lightbox
            mainSrc={currentImage.big_url}
            mainSrcThumbnail={currentImage.profile_url}
            nextSrc={nextImage.big_url}
            nextSrcThumbnail={nextImage.profile_url}
            prevSrc={prevImage.big_url}
            prevSrcThumbnail={prevImage.profile_url}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
        {tiles.map((tile, index) => (
          <li className={classes.item} key={tile.profile_url}>
            <img
              src={tile.profile_url}
              className={classes.img}
              alt={tile.id.toString()}
              onClick={() => handleClick(index)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
export default ImageGridList;
