import * as React from 'react';
import { FC } from 'react';
import {
  BooleanInput,
  NumberInput,
  ArrayInput,
  ReferenceInput,
  SimpleFormIterator,
  AutocompleteInput,
} from 'react-admin';

const DEFAULT_VALUE: unknown = [];

interface Props {
  className?: string;
}

export const SubsourceConfigs: FC<Props> = ({ className }) => {
  return (
    <>
      <h3>Subsource configs:</h3>
      <ArrayInput
        source="tds_path_configs"
        label=""
        className={className}
        defaultValue={DEFAULT_VALUE}
        subscription={{}}
      >
        <SimpleFormIterator>
          <NumberInput
            source="subsource_id"
            defaultValue={null}
            min={0}
            label="Subsource ID"
          />
          <ReferenceInput
            label="Tds Path"
            source="tds_path_id"
            reference="tds/tds-paths"
            filterToQuery={(searchText: any) => ({
              name: '%' + searchText + '%',
            })}
            alwaysOn
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <BooleanInput
            source="is_api_blocked"
            defaultValue={false}
            label="Block API"
          />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
