export const countryChoices = [
  {
    id: 63,
    name: 'AF',
  },
  {
    id: 41,
    name: 'AX',
  },
  {
    id: 46,
    name: 'AL',
  },
  {
    id: 144,
    name: 'DZ',
  },
  {
    id: 241,
    name: 'AS',
  },
  {
    id: 159,
    name: 'AD',
  },
  {
    id: 174,
    name: 'AO',
  },
  {
    id: 196,
    name: 'AI',
  },
  {
    id: 246,
    name: 'AQ',
  },
  {
    id: 200,
    name: 'AG',
  },
  {
    id: 223,
    name: 'AR',
  },
  {
    id: 10,
    name: 'AM',
  },
  {
    id: 203,
    name: 'AW',
  },
  {
    id: 102,
    name: 'AU',
  },
  {
    id: 152,
    name: 'AT',
  },
  {
    id: 34,
    name: 'AZ',
  },
  {
    id: 194,
    name: 'BS',
  },
  {
    id: 22,
    name: 'BH',
  },
  {
    id: 65,
    name: 'BD',
  },
  {
    id: 178,
    name: 'BB',
  },
  {
    id: 39,
    name: 'BY',
  },
  {
    id: 153,
    name: 'BE',
  },
  {
    id: 212,
    name: 'BZ',
  },
  {
    id: 129,
    name: 'BJ',
  },
  {
    id: 195,
    name: 'BM',
  },
  {
    id: 69,
    name: 'BT',
  },
  {
    id: 225,
    name: 'BO',
  },
  {
    id: 249,
    name: 'BQ',
  },
  {
    id: 173,
    name: 'BA',
  },
  {
    id: 55,
    name: 'BW',
  },
  {
    id: 177,
    name: 'BV',
  },
  {
    id: 187,
    name: 'BR',
  },
  {
    id: 72,
    name: 'IO',
  },
  {
    id: 204,
    name: 'VG',
  },
  {
    id: 91,
    name: 'BN',
  },
  {
    id: 45,
    name: 'BG',
  },
  {
    id: 125,
    name: 'BF',
  },
  {
    id: 31,
    name: 'BI',
  },
  {
    id: 179,
    name: 'CV',
  },
  {
    id: 93,
    name: 'KH',
  },
  {
    id: 116,
    name: 'CM',
  },
  {
    id: 242,
    name: 'CA',
  },
  {
    id: 211,
    name: 'KY',
  },
  {
    id: 15,
    name: 'CF',
  },
  {
    id: 136,
    name: 'TD',
  },
  {
    id: 224,
    name: 'CL',
  },
  {
    id: 89,
    name: 'CN',
  },
  {
    id: 103,
    name: 'CX',
  },
  {
    id: 80,
    name: 'CC',
  },
  {
    id: 220,
    name: 'CO',
  },
  {
    id: 52,
    name: 'KM',
  },
  {
    id: 118,
    name: 'CG',
  },
  {
    id: 98,
    name: 'CK',
  },
  {
    id: 217,
    name: 'CR',
  },
  {
    id: 172,
    name: 'HR',
  },
  {
    id: 192,
    name: 'CU',
  },
  {
    id: 248,
    name: 'CW',
  },
  {
    id: 7,
    name: 'CY',
  },
  {
    id: 165,
    name: 'CZ',
  },
  {
    id: 146,
    name: 'DK',
  },
  {
    id: 13,
    name: 'DJ',
  },
  {
    id: 199,
    name: 'DM',
  },
  {
    id: 191,
    name: 'DO',
  },
  {
    id: 12,
    name: 'CD',
  },
  {
    id: 99,
    name: 'TL',
  },
  {
    id: 219,
    name: 'EC',
  },
  {
    id: 28,
    name: 'EG',
  },
  {
    id: 213,
    name: 'SV',
  },
  {
    id: 123,
    name: 'GQ',
  },
  {
    id: 27,
    name: 'ER',
  },
  {
    id: 32,
    name: 'EE',
  },
  {
    id: 57,
    name: 'SZ',
  },
  {
    id: 26,
    name: 'ET',
  },
  {
    id: 188,
    name: 'FK',
  },
  {
    id: 145,
    name: 'FO',
  },
  {
    id: 105,
    name: 'FM',
  },
  {
    id: 114,
    name: 'FJ',
  },
  {
    id: 40,
    name: 'FI',
  },
  {
    id: 158,
    name: 'FR',
  },
  {
    id: 181,
    name: 'GF',
  },
  {
    id: 228,
    name: 'PF',
  },
  {
    id: 78,
    name: 'TF',
  },
  {
    id: 130,
    name: 'GA',
  },
  {
    id: 134,
    name: 'GM',
  },
  {
    id: 37,
    name: 'GE',
  },
  {
    id: 154,
    name: 'DE',
  },
  {
    id: 122,
    name: 'GH',
  },
  {
    id: 133,
    name: 'GI',
  },
  {
    id: 30,
    name: 'GR',
  },
  {
    id: 184,
    name: 'GL',
  },
  {
    id: 210,
    name: 'GD',
  },
  {
    id: 209,
    name: 'GP',
  },
  {
    id: 237,
    name: 'GU',
  },
  {
    id: 214,
    name: 'GT',
  },
  {
    id: 163,
    name: 'GG',
  },
  {
    id: 135,
    name: 'GN',
  },
  {
    id: 127,
    name: 'GW',
  },
  {
    id: 180,
    name: 'GY',
  },
  {
    id: 222,
    name: 'HT',
  },
  {
    id: 17,
    name: 'JO',
  },
  {
    id: 79,
    name: 'HM',
  },
  {
    id: 215,
    name: 'HN',
  },
  {
    id: 90,
    name: 'HK',
  },
  {
    id: 44,
    name: 'HU',
  },
  {
    id: 147,
    name: 'IS',
  },
  {
    id: 70,
    name: 'IN',
  },
  {
    id: 84,
    name: 'ID',
  },
  {
    id: 6,
    name: 'IR',
  },
  {
    id: 4,
    name: 'IQ',
  },
  {
    id: 156,
    name: 'IE',
  },
  {
    id: 162,
    name: 'IM',
  },
  {
    id: 24,
    name: 'IL',
  },
  {
    id: 169,
    name: 'IT',
  },
  {
    id: 121,
    name: 'CI',
  },
  {
    id: 190,
    name: 'JM',
  },
  {
    id: 95,
    name: 'JP',
  },
  {
    id: 161,
    name: 'JE',
  },
  {
    id: 76,
    name: 'KZ',
  },
  {
    id: 11,
    name: 'KE',
  },
  {
    id: 230,
    name: 'KI',
  },
  {
    id: 49,
    name: 'XK',
  },
  {
    id: 19,
    name: 'KW',
  },
  {
    id: 77,
    name: 'KG',
  },
  {
    id: 85,
    name: 'LA',
  },
  {
    id: 33,
    name: 'LV',
  },
  {
    id: 18,
    name: 'LB',
  },
  {
    id: 54,
    name: 'LS',
  },
  {
    id: 120,
    name: 'LR',
  },
  {
    id: 115,
    name: 'LY',
  },
  {
    id: 160,
    name: 'LI',
  },
  {
    id: 155,
    name: 'LU',
  },
  {
    id: 92,
    name: 'MO',
  },
  {
    id: 62,
    name: 'MG',
  },
  {
    id: 53,
    name: 'MW',
  },
  {
    id: 88,
    name: 'MY',
  },
  {
    id: 71,
    name: 'MV',
  },
  {
    id: 138,
    name: 'ML',
  },
  {
    id: 143,
    name: 'MT',
  },
  {
    id: 104,
    name: 'MH',
  },
  {
    id: 193,
    name: 'MQ',
  },
  {
    id: 128,
    name: 'MR',
  },
  {
    id: 56,
    name: 'MU',
  },
  {
    id: 60,
    name: 'YT',
  },
  {
    id: 227,
    name: 'MX',
  },
  {
    id: 157,
    name: 'MC',
  },
  {
    id: 101,
    name: 'MN',
  },
  {
    id: 171,
    name: 'ME',
  },
  {
    id: 206,
    name: 'MS',
  },
  {
    id: 142,
    name: 'MA',
  },
  {
    id: 61,
    name: 'MZ',
  },
  {
    id: 74,
    name: 'MM',
  },
  {
    id: 175,
    name: 'NA',
  },
  {
    id: 109,
    name: 'NR',
  },
  {
    id: 73,
    name: 'NP',
  },
  {
    id: 151,
    name: 'NL',
  },
  {
    id: 111,
    name: 'NC',
  },
  {
    id: 113,
    name: 'NZ',
  },
  {
    id: 216,
    name: 'NI',
  },
  {
    id: 137,
    name: 'NE',
  },
  {
    id: 124,
    name: 'NG',
  },
  {
    id: 235,
    name: 'NU',
  },
  {
    id: 112,
    name: 'NF',
  },
  {
    id: 96,
    name: 'KP',
  },
  {
    id: 43,
    name: 'MK',
  },
  {
    id: 236,
    name: 'MP',
  },
  {
    id: 166,
    name: 'NO',
  },
  {
    id: 20,
    name: 'OM',
  },
  {
    id: 64,
    name: 'PK',
  },
  {
    id: 81,
    name: 'PW',
  },
  {
    id: 244,
    name: 'PS',
  },
  {
    id: 221,
    name: 'PA',
  },
  {
    id: 106,
    name: 'PG',
  },
  {
    id: 185,
    name: 'PY',
  },
  {
    id: 226,
    name: 'PE',
  },
  {
    id: 87,
    name: 'PH',
  },
  {
    id: 229,
    name: 'PN',
  },
  {
    id: 47,
    name: 'PL',
  },
  {
    id: 119,
    name: 'PT',
  },
  {
    id: 238,
    name: 'PR',
  },
  {
    id: 21,
    name: 'QA',
  },
  {
    id: 35,
    name: 'LT',
  },
  {
    id: 38,
    name: 'MD',
  },
  {
    id: 58,
    name: 'RE',
  },
  {
    id: 48,
    name: 'RO',
  },
  {
    id: 100,
    name: 'RU',
  },
  {
    id: 1,
    name: 'RW',
  },
  {
    id: 208,
    name: 'BL',
  },
  {
    id: 176,
    name: 'SH',
  },
  {
    id: 201,
    name: 'LC',
  },
  {
    id: 207,
    name: 'MF',
  },
  {
    id: 183,
    name: 'PM',
  },
  {
    id: 205,
    name: 'VC',
  },
  {
    id: 234,
    name: 'WS',
  },
  {
    id: 168,
    name: 'SM',
  },
  {
    id: 132,
    name: 'ST',
  },
  {
    id: 5,
    name: 'SA',
  },
  {
    id: 117,
    name: 'SN',
  },
  {
    id: 245,
    name: 'RS',
  },
  {
    id: 16,
    name: 'SC',
  },
  {
    id: 131,
    name: 'SL',
  },
  {
    id: 97,
    name: 'SG',
  },
  {
    id: 247,
    name: 'SX',
  },
  {
    id: 164,
    name: 'SK',
  },
  {
    id: 170,
    name: 'SI',
  },
  {
    id: 107,
    name: 'SB',
  },
  {
    id: 2,
    name: 'SO',
  },
  {
    id: 59,
    name: 'ZA',
  },
  {
    id: 189,
    name: 'GS',
  },
  {
    id: 94,
    name: 'KR',
  },
  {
    id: 250,
    name: 'SS',
  },
  {
    id: 141,
    name: 'ES',
  },
  {
    id: 68,
    name: 'LK',
  },
  {
    id: 198,
    name: 'KN',
  },
  {
    id: 29,
    name: 'SD',
  },
  {
    id: 182,
    name: 'SR',
  },
  {
    id: 36,
    name: 'SJ',
  },
  {
    id: 150,
    name: 'SE',
  },
  {
    id: 149,
    name: 'CH',
  },
  {
    id: 9,
    name: 'SY',
  },
  {
    id: 86,
    name: 'TW',
  },
  {
    id: 67,
    name: 'TJ',
  },
  {
    id: 8,
    name: 'TZ',
  },
  {
    id: 83,
    name: 'TH',
  },
  {
    id: 126,
    name: 'TG',
  },
  {
    id: 231,
    name: 'TK',
  },
  {
    id: 232,
    name: 'TO',
  },
  {
    id: 197,
    name: 'TT',
  },
  {
    id: 140,
    name: 'TN',
  },
  {
    id: 25,
    name: 'TR',
  },
  {
    id: 66,
    name: 'TM',
  },
  {
    id: 202,
    name: 'TC',
  },
  {
    id: 108,
    name: 'TV',
  },
  {
    id: 240,
    name: 'UM',
  },
  {
    id: 239,
    name: 'VI',
  },
  {
    id: 14,
    name: 'UG',
  },
  {
    id: 42,
    name: 'UA',
  },
  {
    id: 23,
    name: 'AE',
  },
  {
    id: 148,
    name: 'GB',
  },
  {
    id: 243,
    name: 'US',
  },
  {
    id: 186,
    name: 'UY',
  },
  {
    id: 75,
    name: 'UZ',
  },
  {
    id: 110,
    name: 'VU',
  },
  {
    id: 167,
    name: 'VA',
  },
  {
    id: 218,
    name: 'VE',
  },
  {
    id: 82,
    name: 'VN',
  },
  {
    id: 233,
    name: 'WF',
  },
  {
    id: 139,
    name: 'EH',
  },
  {
    id: 3,
    name: 'YE',
  },
  {
    id: 51,
    name: 'ZM',
  },
  {
    id: 50,
    name: 'ZW',
  },
];

export const countryChoicesWithAll = [
  {
    id: 0,
    name: 'ALL',
  },
  ...countryChoices,
];

export const countryChoicesWithoutId = countryChoices.map(
  (choice: { id: number; name: string }) => {
    return { id: choice.name, name: choice.name };
  },
);

export const countryChoicesAllWithoutId = countryChoicesWithAll.map(
  (choice: { id: number; name: string }) => {
    return { id: choice.name, name: choice.name };
  },
);

export const getCountryNameChoices = (): object[] => {
  let result = [];
  for (let row of countryChoices) {
    result.push({
      id: row.name,
      name: row.name,
    });
  }

  return result;
};
