import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  UrlField,
  Filter,
  NumberInput,
  NullableBooleanInput,
  BooleanField,
  ReferenceField,
  ResourceComponentProps,
} from 'react-admin';
import IconField from '../../layout/fields/IconField';

const WebpushTplFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" alwaysOn />
    <NumberInput source="type" alwaysOn />
    <NullableBooleanInput source="is_active" alwaysOn />
  </Filter>
);

const WebpushTplList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<WebpushTplFilter />}
      perPage={25}
      sort={{ field: 'updated_at', order: 'DESC' }}
      title="Webpush templates"
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />
        <NumberField source="type" />
        <ReferenceField
          label="Title"
          source="title_text_source_id"
          reference="translations/text-sources"
        >
          <TextField source="value" />
        </ReferenceField>
        <ReferenceField
          label="Body"
          source="body_text_source_id"
          reference="translations/text-sources"
        >
          <TextField source="value" />
        </ReferenceField>
        <IconField source="icon_url" label="Icon" />
        <IconField source="image_url" label="Image" />
        <UrlField source="link" target="_blank" />
        <BooleanField source="is_active" />
        <DateField source="created_at" locales="ru-RU" showTime />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};

export default WebpushTplList;
