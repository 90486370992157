import OfferIcon from '@material-ui/icons/CallSplit';

import OfferList from './OfferList';
import OfferEdit from './OfferEdit';
import OfferCreate from './OfferCreate';

export default {
  list: OfferList,
  edit: OfferEdit,
  create: OfferCreate,
  icon: OfferIcon,
};
