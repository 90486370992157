import React, { useCallback, useEffect, useState } from 'react';
import {
  List,
  ResourceComponentProps,
  Filter,
  NullableBooleanInput,
  TextInput,
  SelectInput,
} from 'react-admin';

import { ChoicesApi } from 'api/ChoicesApi';

import Loader from 'layout/Loader';
import PackageInfoFields from './submodules/PackageInfoFields';

import { IChoice } from 'types/global';

const PackageInfoFilter = ({
  productSubtypeChoices,
  currencyChoices,
  ...props
}: {
  productSubtypeChoices: IChoice[];
  currencyChoices: IChoice[];
}) => (
  <Filter {...props}>
    <TextInput source="id" label="Id" style={{ width: '120px' }} alwaysOn />
    <TextInput source="name" label="Name" style={{ width: '120px' }} alwaysOn />
    <SelectInput
      source="currency"
      choices={currencyChoices}
      loading={currencyChoices.length === 0}
      style={{ width: '120px' }}
      alwaysOn
    />
    <SelectInput
      source="product_subtype"
      choices={productSubtypeChoices}
      loading={productSubtypeChoices.length === 0}
      style={{ width: '140px' }}
      alwaysOn
    />
    <NullableBooleanInput label="Is active" source="is_active" alwaysOn />
    <NullableBooleanInput label="Is default" source="is_default" alwaysOn />
  </Filter>
);

const PackageInfoList = (props: ResourceComponentProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const [currencyChoices, setCurrencyChoices] = useState<IChoice[]>([]);
  const [retryLogicChoices, setRetryLogicChoices] = useState<IChoice[]>([]);
  const [displayPriceChoices, setDisplayPriceChoices] = useState<IChoice[]>([]);
  const [discountTypeChoices, setDiscountTypeChoices] = useState<IChoice[]>([]);
  const [productSubtypeChoices, setProductSubtypeChoices] = useState<IChoice[]>(
    [],
  );

  const setChoices = useCallback(async () => {
    const currency = await ChoicesApi.fetchCurrencyChoices();
    const retryLogic = await ChoicesApi.fetchRetryLogicChoices();

    const displayPrice = await ChoicesApi.fetchDisplayPriceTypeChoices();
    const discountType = await ChoicesApi.fetchDiscountTypeChoices();
    const productSubtype = await ChoicesApi.fetchProductSubtypeChoices();

    setCurrencyChoices(currency);
    setRetryLogicChoices(retryLogic);
    setDisplayPriceChoices(displayPrice);
    setDiscountTypeChoices(discountType);
    setProductSubtypeChoices(productSubtype);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    setChoices();
  }, []);

  return (
    <List
      {...props}
      filters={
        <PackageInfoFilter
          productSubtypeChoices={productSubtypeChoices}
          currencyChoices={currencyChoices}
        />
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <PackageInfoFields
          productSubtypeChoices={productSubtypeChoices}
          displayPriceChoices={displayPriceChoices}
          discountTypeChoices={discountTypeChoices}
          retryLogicChoices={retryLogicChoices}
        />
      )}
    </List>
  );
};
export default PackageInfoList;
