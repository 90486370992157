import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Title, useAuthenticated, useNotify } from 'react-admin';
import { useHistory, useParams } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import Lightbox from 'react-image-lightbox';

import { Card, CardContent, Grid, Chip } from '@material-ui/core';
import {
  ThumbUp,
  RemoveCircle,
  Delete,
  SkipNext,
  RotateRight,
  Crop as CropIcon,
} from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AxiosResponse } from 'axios';
import { httpClient } from 'httpClient';
import { usePhotoModeration } from 'hooks/usePhotoModeration';

import SuccessButton from 'layout/buttons/SuccessButton';
import ErrorButton from 'layout/buttons/ErrorButton';
import GreyButton from 'layout/buttons/GreyButton';
import Gender from 'layout/Gender';
import ImageGridList from './ImageGridList';

import { EventEmitter } from 'eventEmitter';

import { EmitterEvents } from 'types/enums/EmitterEvents';
import { UserShort, UserPhoto, UserAdditionalInfo } from 'model-types';

import 'react-image-lightbox/style.css';
import 'react-image-crop/dist/ReactCrop.css';

const UserPhotoModeration = () => {
  useAuthenticated();

  const history = useHistory();
  const notify = useNotify();

  let { initPhotoId }: any = useParams();
  initPhotoId = initPhotoId ? parseInt(initPhotoId) : null;

  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState<UserPhoto | null>(null);
  const [photoId, setPhotoId] = useState<number | null>(initPhotoId);
  const [tiles, setTiles] = useState<UserPhoto[]>([]);
  const [user, setUser] = useState<UserShort | null>(null);
  const [userAdditionalInfo, setUserAdditionalInfo] =
    useState<UserAdditionalInfo | null>(null);
  const [unModeratedCount, setUnModeratedCount] = useState<number | null>(null);
  const [lastModeratedAt, setLastModeratedAt] = useState<Date | null>(null);
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [isZoom, setIsZoom] = useState(false);

  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const {
    isCropDisabled,
    cropBorder,
    setCropBorder,
    setCompletedCrop,
    activeCropImg,
    saveCroppedImg,
    closeCroppedImg,
    onImageLoad,
  } = usePhotoModeration({
    photo,
    previewCanvasRef,
    imgRef,
    setIsImgLoading,
  });

  const handleZoomClick = () => {
    if (isCropDisabled) setIsZoom(true);
  };
  const handleZoomClose = () => setIsZoom(false);

  const changePhoto = (response: AxiosResponse) => {
    const photoId = response.data.photo?.id ?? null;
    const newUrl = '/photo/moderation' + (photoId ? '/' + photoId : '/user');

    if (!document.URL.endsWith(newUrl)) {
      history.push(newUrl);
    }

    setPhotoId(photoId);
    setUser(response.data.user ?? null);
    setTiles(response.data.photos ?? []);
    setPhoto(response.data.photo ?? null);
    setUnModeratedCount(response.data.unmoderated_count);
    setLastModeratedAt(new Date(response.data.last_moderated_at));

    setIsLoading(false);
  };

  const changePhotoWithCounters = (response: AxiosResponse) => {
    changePhoto(response);
    EventEmitter.dispatch(EmitterEvents.ReloadModerationCounters);
  };

  const getPhoto = (getId = photoId) => {
    httpClient
      .get('/photo/moderation' + (getId ? '/' + getId : '/user'))
      .then(changePhoto);
  };

  const changeAdditionalUserInfo = (response: AxiosResponse) => {
    if (response.data) setUserAdditionalInfo(response.data ?? null);
  };

  const handleError = (err: any) => {
    notify(
      err?.message || 'Oops, something went wrong. Reload page and try again!',
    );

    setIsLoading(false);
  };

  const approve = () => {
    if (!photo || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/photo/moderation/approve/' + photo.id)
      .then(changePhotoWithCounters)
      .catch(handleError);
  };

  const ban = () => {
    if (!photo || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/photo/moderation/ban/' + photo.id)
      .then(changePhotoWithCounters)
      .catch(handleError);
  };

  const remove = () => {
    if (!photo || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/photo/moderation/delete/' + photo.id)
      .then(changePhotoWithCounters)
      .catch(handleError);
  };

  const skip = () => {
    if (!photo || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/photo/moderation/skip/' + photo.id)
      .then(changePhoto)
      .catch(handleError);
  };

  const rotate = () => {
    if (!photo) return;

    httpClient
      .get('/photo/moderation/rotate/' + photo.id)
      .then(changePhoto)
      .catch(handleError);
  };

  const getAdditionalUserInfo = useCallback(() => {
    if (user?.id) {
      httpClient
        .get(`/users/specific/${user.id}`)
        .then(changeAdditionalUserInfo)
        .catch(handleError);
    }
  }, [user?.id]);

  useEffect(() => {
    if (
      initPhotoId &&
      initPhotoId !== photoId &&
      document.URL.endsWith('/photo/moderation/' + initPhotoId)
    ) {
      getPhoto(initPhotoId);
    }
  }, [initPhotoId, photoId]);

  useEffect(getPhoto, [photoId]);

  useEffect(() => {
    EventEmitter.subscribe(EmitterEvents.PhotoModerationReload, () => {
      if (!photo) getPhoto();
    });

    return () => {
      EventEmitter.unsubscribe(EmitterEvents.PhotoModerationReload);
    };
  }, [photo]);

  useEffect(getAdditionalUserInfo, [getAdditionalUserInfo]);

  return (
    <Card>
      <Title title="User Photo Moderation" />
      <CardContent>
        {photo && user && (
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Grid item xs={12}>
                <SuccessButton
                  endIcon={<ThumbUp />}
                  onClick={approve}
                  disabled={isLoading}
                >
                  Approve
                </SuccessButton>
                <ErrorButton
                  endIcon={<RemoveCircle />}
                  onClick={ban}
                  disabled={isLoading}
                >
                  Ban
                </ErrorButton>
                <ErrorButton
                  endIcon={<Delete />}
                  onClick={remove}
                  disabled={isLoading}
                >
                  Delete
                </ErrorButton>
              </Grid>
              <Grid item xs={12}>
                <GreyButton
                  endIcon={<SkipNext />}
                  onClick={skip}
                  disabled={isLoading}
                >
                  Skip
                </GreyButton>
                <GreyButton endIcon={<RotateRight />} onClick={rotate}>
                  Rotate
                </GreyButton>
                {isCropDisabled ? (
                  <GreyButton
                    endIcon={<CropIcon />}
                    onClick={activeCropImg}
                    disabled={isLoading}
                  >
                    Crop
                  </GreyButton>
                ) : (
                  <>
                    <SuccessButton
                      endIcon={<CropIcon />}
                      onClick={saveCroppedImg}
                    >
                      Save Crop
                    </SuccessButton>
                    <ErrorButton
                      endIcon={<CropIcon />}
                      onClick={closeCroppedImg}
                    >
                      Close Crop
                    </ErrorButton>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Chip
                label={unModeratedCount + ' remains'}
                variant="outlined"
                style={{ margin: '5px' }}
              />
              <Chip
                label={'Last Moderation: ' + lastModeratedAt?.toLocaleString()}
                variant="outlined"
                style={{ margin: '5px' }}
              />
            </Grid>
            <Grid item xs={12}>
              #{user.id} <Gender type={user.gender} />, Country:{' '}
              {userAdditionalInfo?.country_name || 'unknown'}, Host:{' '}
              {userAdditionalInfo?.host || 'unknown'}
            </Grid>
            <Grid item xs={12}>
              {isImgLoading ? (
                <div>
                  <CircularProgress color="inherit" />
                </div>
              ) : (
                <ReactCrop
                  crop={cropBorder}
                  onChange={(_, percentCrop) => setCropBorder(percentCrop)}
                  onComplete={setCompletedCrop}
                  disabled={isCropDisabled}
                >
                  {photo.is_main ? 'Profile' : 'Public'}
                  <img
                    ref={imgRef}
                    src={photo.big_url}
                    alt={String(user.id)}
                    onLoad={onImageLoad}
                    style={{ maxWidth: '100%', maxHeight: '60vh' }}
                    crossOrigin="anonymous"
                    onClick={handleZoomClick}
                  />
                </ReactCrop>
              )}
              {isZoom && (
                <Lightbox
                  mainSrc={photo.big_url}
                  onCloseRequest={handleZoomClose}
                />
              )}
              <div>
                <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
              </div>
            </Grid>
            <Grid item xs={12}>
              Other user photo:
            </Grid>
            <Grid item xs={12}>
              <ImageGridList tiles={tiles} />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default UserPhotoModeration;
