import SpeakerIcon from '@material-ui/icons/Speaker';
import BannerList from './BannerList';
import BannerCreate from './BannerCreate';
import BannerEdit from './BannerEdit';

export default {
  list: BannerList,
  edit: BannerEdit,
  create: BannerCreate,
  icon: SpeakerIcon,
};
