import React, { FC } from 'react';
import {
  ResourceComponentProps,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';

const LegalInfoForm: FC<ResourceComponentProps> = ({ ...props }) => {
  return (
    <SimpleForm {...props} redirect="list">
      <TextInput
        source="address"
        multiline
        style={{ width: '100%' }}
        validate={required()}
      />
      <TextInput source="short_address" multiline style={{ width: '100%' }} />
      <TextInput source="company" validate={required()} />
      <TextInput source="governing_law" validate={required()} />
    </SimpleForm>
  );
};

export default LegalInfoForm;
