import PackagesIcon from '@material-ui/icons/LocalMall';
import PackagesList from './PackagesList';
import PackagesEdit from './PackagesEdit';
import PackagesCreate from './PackagesCreate';

export default {
  list: PackagesList,
  edit: PackagesEdit,
  create: PackagesCreate,
  icon: PackagesIcon,
};
