import * as React from 'react';
import { FC } from 'react';
import { SimpleForm, TextInput, SelectInput } from 'react-admin';
import {
  Hosts,
  Direction,
  hostsChoices,
  directionChoices,
} from './field-choices';
import { TdsPath } from '../../model-types';
import { Toolbar } from 'react-admin';
import { validationForm } from './validation';
import { NumberInput } from 'react-admin';

interface TdsPathProps {
  record?: TdsPath;
}

const TdsPathForm: FC<TdsPathProps> = ({ record, ...rest }) => {
  return (
    <SimpleForm
      redirect="list"
      record={record}
      toolbar={<Toolbar alwaysEnableSaveButton />}
      validate={validationForm}
      {...rest}
    >
      <TextInput source="name" />
      <SelectInput
        source="host"
        defaultValue={Hosts.Hookupers}
        choices={hostsChoices}
      />
      <SelectInput
        source="direction"
        defaultValue={Direction.Landing}
        choices={directionChoices}
      />
      <NumberInput source="landing_number" />
      <TextInput source="destination_path" />
    </SimpleForm>
  );
};

export default TdsPathForm;
