import React, { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import CampaignsForm from './CampaignsForm';

const CampaignsEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit {...props}>
      <CampaignsForm isEdit />
    </Edit>
  );
};
export default CampaignsEdit;
