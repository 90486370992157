import * as React from 'react';
import { Link } from 'react-router-dom';
import textSource from '../textSource';
import Button from '@material-ui/core/Button';

const LinkToRelatedSources = (props: any) => {
  let record = props.record;
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/translations/text-sources',
        search: `filter=${JSON.stringify({ category_id: record.id })}`,
      }}
    >
      <textSource.icon />
      &nbsp;Text Sources
    </Button>
  ) : null;
};

export default LinkToRelatedSources;
