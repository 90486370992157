import React, { useState, useEffect, useCallback } from 'react';
import { Title, useAuthenticated, useNotify } from 'react-admin';
import { useHistory, useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';

import { Card, CardContent, Grid, Chip } from '@material-ui/core';
import {
  ThumbUp,
  RemoveCircle,
  Delete,
  SkipNext,
  RotateRight,
} from '@material-ui/icons';
import { httpClient } from 'httpClient';
import { AxiosResponse } from 'axios';
import { EventEmitter } from 'eventEmitter';

import SuccessButton from 'layout/buttons/SuccessButton';
import ErrorButton from 'layout/buttons/ErrorButton';
import GreyButton from 'layout/buttons/GreyButton';
import Gender from 'layout/Gender';

import { UserAdditionalInfo, UserShort, UserVideo } from 'model-types';
import { EmitterEvents } from 'types/enums/EmitterEvents';

import 'react-image-lightbox/style.css';

const VideoModeration = () => {
  useAuthenticated();

  const history = useHistory();
  const notify = useNotify();

  let { initVideoId }: any = useParams();
  initVideoId = initVideoId ? parseInt(initVideoId) : null;

  const [isLoading, setIsLoading] = useState(false);
  const [video, setVideo] = useState<UserVideo | null>(null);
  const [videoId, setVideoId] = useState<number | null>(initVideoId);
  const [user, setUser] = useState<UserShort | null>(null);
  const [userAdditionalInfo, setUserAdditionalInfo] =
    useState<UserAdditionalInfo | null>(null);
  const [unModeratedCount, setUnModeratedCount] = useState<number | null>(null);
  const [lastModeratedAt, setLastModeratedAt] = useState<Date | null>(null);

  const [isZoom, setIsZoom] = useState(false);

  const handleZoomClick = () => setIsZoom(true);
  const handleZoomClose = () => setIsZoom(false);

  const changeVideo = (response: AxiosResponse) => {
    const videoId = response.data.video?.id ?? null;
    const newUrl = '/video/moderation' + (videoId ? '/' + videoId : '');

    if (!document.URL.endsWith(newUrl)) {
      history.push(newUrl);
    }

    setVideoId(videoId);
    setUser(response.data.user ?? null);
    setVideo(response.data.video ?? null);
    setUnModeratedCount(response.data.unmoderated_count);
    setLastModeratedAt(new Date(response.data.last_moderated_at));

    setIsLoading(false);
  };

  const changeVideoWithCounters = (response: AxiosResponse) => {
    changeVideo(response);
    EventEmitter.dispatch(EmitterEvents.ReloadModerationCounters);
  };

  const getVideo = (getId = videoId) => {
    httpClient
      .get('/video/moderation' + (getId ? '/' + getId : ''))
      .then(changeVideo);
  };

  const changeAdditionalUserInfo = (response: AxiosResponse) => {
    if (response.data) setUserAdditionalInfo(response.data ?? null);
  };

  const handleError = (err: any) => {
    notify(
      err?.message || 'Oops, something went wrong. Reload page and try again!',
    );

    setIsLoading(false);
  };

  const approve = () => {
    if (!video || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/video/moderation/approve/' + video.id)
      .then(changeVideoWithCounters)
      .catch(handleError);
  };

  const ban = () => {
    if (!video || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/video/moderation/ban/' + video.id)
      .then(changeVideoWithCounters)
      .catch(handleError);
  };

  const remove = () => {
    if (!video || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/video/moderation/delete/' + video.id)
      .then(changeVideoWithCounters)
      .catch(handleError);
  };

  const skip = () => {
    if (!video || isLoading) return;

    setIsLoading(true);

    httpClient
      .get('/video/moderation/skip/' + video.id)
      .then(changeVideo)
      .catch(handleError);
  };

  const regeneratePreview = () => {
    if (!video || isLoading) return;

    setIsLoading(true);

    httpClient
      .post('/videos/' + video.id + '/regenerate-preview')
      .then(changeVideo)
      .catch(handleError);
  };

  const getAdditionalUserInfo = useCallback(() => {
    if (user?.id) {
      httpClient
        .get(`/users/specific/${user.id}`)
        .then(changeAdditionalUserInfo);
    }
  }, [user?.id]);

  useEffect(() => {
    if (
      initVideoId &&
      initVideoId !== videoId &&
      document.URL.endsWith('/video/moderation/' + initVideoId)
    ) {
      getVideo(initVideoId);
    }
  }, [initVideoId, videoId]);

  useEffect(getVideo, [videoId]);

  useEffect(() => {
    EventEmitter.subscribe(EmitterEvents.PhotoModerationReload, () => {
      if (!video) getVideo();
    });

    return () => {
      EventEmitter.unsubscribe(EmitterEvents.PhotoModerationReload);
    };
  }, [video]);

  useEffect(getAdditionalUserInfo, [getAdditionalUserInfo]);

  return (
    <Card>
      <Title title="Video Moderation" />
      <CardContent>
        {video && user && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SuccessButton endIcon={<ThumbUp />} onClick={approve}>
                Approve
              </SuccessButton>
              <GreyButton endIcon={<RotateRight />} onClick={regeneratePreview}>
                Regenerate preview
              </GreyButton>
              <ErrorButton endIcon={<RemoveCircle />} onClick={ban}>
                Ban
              </ErrorButton>
              <ErrorButton endIcon={<Delete />} onClick={remove}>
                Delete
              </ErrorButton>
              <GreyButton endIcon={<SkipNext />} onClick={skip}>
                Skip
              </GreyButton>
            </Grid>
            <Grid item xs={12}>
              <Chip
                label={unModeratedCount + ' remains'}
                variant="outlined"
                style={{ margin: '5px' }}
              />
              <Chip
                label={'last moderation: ' + lastModeratedAt?.toLocaleString()}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              #{user.id} <Gender type={user.gender} />, Country:{' '}
              {userAdditionalInfo?.country_name || 'unknown'}, Host:{' '}
              {userAdditionalInfo?.host || 'unknown'}
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>Video:</h3>
              <video
                src={video.url}
                style={{ maxWidth: '100%', maxHeight: '60vh' }}
                controls
              >
                Your browser does not support the video tag.
              </video>
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>Preview:</h3>
              <img
                src={video.preview_url}
                style={{
                  maxWidth: '100%',
                  maxHeight: '60vh',
                  cursor: 'zoom-in',
                }}
                onClick={handleZoomClick}
              />
              {isZoom && (
                <Lightbox
                  mainSrc={video.preview_url}
                  onCloseRequest={handleZoomClose}
                />
              )}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
export default VideoModeration;
