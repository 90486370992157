import BrandIcon from '@material-ui/icons/Flare';
import BrandList from './BrandList';
import BrandEdit from './BrandEdit';
import BrandCreate from './BrandCreate';

export default {
  list: BrandList,
  edit: BrandEdit,
  create: BrandCreate,
  icon: BrandIcon,
};
