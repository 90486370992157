import * as React from 'react';
import { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import OfferForm from './OfferForm';

const OfferCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Offer" {...props}>
      <OfferForm />
    </Create>
  );
};

export default OfferCreate;
