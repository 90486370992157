import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { MarketingFlow } from '../../model-types';
import MarketingFlowForm from './MarketingFlowForm';

interface PartnerProps {
  record?: MarketingFlow;
}

const MarketingFlowTitle: FC<PartnerProps> = ({ record }) =>
  record ? <span>MarketingFlow #{record.id}</span> : null;

const MarketingFlowEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<MarketingFlowTitle />} {...props}>
      <MarketingFlowForm />
    </Edit>
  );
};

export default MarketingFlowEdit;
