import { AffiliateNetwork } from 'model-types';
import { FC } from 'react';
import { required, SimpleForm, TextInput } from 'react-admin';
import * as React from 'react';

interface AffiliateNetworkProps {
  record?: AffiliateNetwork;
}

const AffiliateNetworkForm: FC<AffiliateNetworkProps> = ({
  record,
  ...rest
}) => {
  if (!record) {
    return null;
  }
  return (
    <SimpleForm redirect="list" record={record} {...rest}>
      <TextInput source="name" validate={[required()]} />
    </SimpleForm>
  );
};
export default AffiliateNetworkForm;
