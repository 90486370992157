import * as React from 'react';
import { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';

import AffiliateNetworkForm from './AffiliateNetworkForm';

const AffiliateNetworkCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Affiliate Network" {...props}>
      <AffiliateNetworkForm />
    </Create>
  );
};
export default AffiliateNetworkCreate;
