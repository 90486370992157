import React, { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import LegalInfoForm from './LegalInfoForm';

const LegalInfoEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title="Edit legal info" {...props}>
      <LegalInfoForm />
    </Edit>
  );
};

export default LegalInfoEdit;
