import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  BooleanInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { ChoicesApi } from 'api/ChoicesApi';

import ReferencePackageInfo from './submodules/ReferencePackageInfo';

import { countryChoicesWithoutId } from 'countries';
import { IChoice } from 'types/global';
import { siteGroupIdChoices } from 'model-types';
import { Campaigns } from 'types/packages/packages';

interface CampaignsProps {
  record?: Campaigns;
  isEdit?: boolean;
}

const CampaignsForm: FC<CampaignsProps> = ({ record, isEdit, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [trialChoices, setTrialChoices] = useState<IChoice[]>([]);
  const [currencyChoices, setCurrencyChoices] = useState<IChoice[]>([]);
  const [retryLogicChoices, setRetryLogicChoices] = useState<IChoice[]>([]);
  const [formDesignChoices, setFormDesignChoices] = useState<IChoice[]>([]);
  const [discountTypeChoices, setDiscountTypeChoices] = useState<IChoice[]>([]);
  const [displayPriceChoices, setDisplayPriceChoices] = useState<IChoice[]>([]);
  const [productSubtypeChoices, setProductSubtypeChoices] = useState<IChoice[]>(
    [],
  );

  const setChoices = useCallback(async () => {
    const trial = await ChoicesApi.fetchTrialChoices();
    const currency = await ChoicesApi.fetchCurrencyChoices();
    const retryLogic = await ChoicesApi.fetchRetryLogicChoices();
    const formDesign = await ChoicesApi.fetchPaymentFormDesignChoices();
    const displayPrice = await ChoicesApi.fetchDisplayPriceTypeChoices();
    const discountType = await ChoicesApi.fetchDiscountTypeChoices();
    const productSubtype = await ChoicesApi.fetchProductSubtypeChoices();

    setTrialChoices(trial);
    setCurrencyChoices(currency);
    setRetryLogicChoices(retryLogic);
    setFormDesignChoices(formDesign);
    setDisplayPriceChoices(displayPrice);
    setDiscountTypeChoices(discountType);
    setProductSubtypeChoices(productSubtype);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    setChoices();
  }, []);

  if (!record) return null;

  return (
    <SimpleForm {...rest} redirect="list">
      <div style={{ width: '50%' }}>
        <TextInput
          source="name"
          validate={[required()]}
          style={{ width: '150px', marginRight: '10px' }}
        />
        <TextInput
          source="description"
          style={{ width: '150px', marginRight: '10px' }}
        />
        <SelectInput
          source="payment_form_design"
          choices={formDesignChoices}
          loading={formDesignChoices.length === 0}
          validate={[required()]}
          style={{ width: '180px', marginRight: '10px' }}
        />
        <SelectInput
          source="trial"
          choices={trialChoices}
          loading={trialChoices.length === 0}
          validate={[required()]}
          style={{ width: '150px', marginRight: '10px' }}
        />
        <SelectArrayInput
          source="countries"
          choices={countryChoicesWithoutId}
          style={{ width: '150px', marginRight: '10px' }}
        />
        <SelectArrayInput
          label="Site group"
          source="site_group_ids"
          choices={siteGroupIdChoices}
          validate={[required()]}
          style={{ width: '150px', marginRight: '10px' }}
        />
      </div>
      <div style={{ width: '50%', display: 'flex' }}>
        <BooleanInput source="with_upsales" defaultValue={false} />
        <BooleanInput source="is_active" defaultValue={false} />
      </div>
      {isEdit && (
        <ReferencePackageInfo
          productSubtypeChoices={productSubtypeChoices}
          displayPriceChoices={displayPriceChoices}
          discountTypeChoices={discountTypeChoices}
          retryLogicChoices={retryLogicChoices}
          currencyChoices={currencyChoices}
          isChoicesLoading={isLoading}
        />
      )}
    </SimpleForm>
  );
};
export default CampaignsForm;
