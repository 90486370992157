import * as React from 'react';
import { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import GeoSegmentForm from './GeoSegmentForm';

const GeoSegmentCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Geo Segment" {...props}>
      <GeoSegmentForm />
    </Create>
  );
};
export default GeoSegmentCreate;
