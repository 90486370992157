import { siteIdChoices } from 'model-types';

enum Env {
  Production = 'production',
  Development = 'development',
  Stage = 'stage',
}

const BASE_DEV_DOMAIN = 'devo4qa.com';
const BASE_PROD_DOMAIN = 'hookupers.com';

const getDevHostnameParts = (hostname: string) => {
  const reg = new RegExp(`(stage|dev|hook-\\d+)-admin\\.(${BASE_DEV_DOMAIN})`);
  const parts = hostname.match(reg);

  return {
    env: parts ? parts[1] : Env.Production,
    domain: parts ? parts[2] : BASE_PROD_DOMAIN,
  };
};

export const getEnvByHostname = (hostname: string) => {
  const { env: envPart } = getDevHostnameParts(hostname);

  if (envPart === 'dev' || process.env.NODE_ENV === Env.Development) {
    return Env.Development;
  }

  if (envPart === 'stage') {
    return Env.Stage;
  }

  if (envPart?.includes('hook')) {
    return Env.Stage;
  }

  return Env.Production;
};

export const getApiUrl = (hostname: string, env: Env): string => {
  if (env === Env.Production) {
    return `https://api.${BASE_PROD_DOMAIN}`;
  }

  const { env: envPart, domain: domainPart } = getDevHostnameParts(hostname);

  return `https://${envPart}-api.${domainPart}`;
};

export const getWsUrl = (hostname: string, env: Env): string => {
  if (env === Env.Production) {
    return `wss://ws.${BASE_PROD_DOMAIN}`;
  }

  const { env: envPart, domain: domainPart } = getDevHostnameParts(hostname);

  return `wss://${envPart}-ws.${domainPart}`;
};

export const getEnv = (): Env => {
  const currentHostname = window.location.hostname;
  return getEnvByHostname(currentHostname);
};

export const isInternalUrl = (url: string): boolean => {
  const env = getEnv();
  const domain = new URL(url).hostname;
  const isInternal = siteIdChoices.map((t) => t.name).includes(domain);

  if (env === Env.Production || isInternal) {
    return isInternal;
  }
  const pattern = new RegExp(`^s\\d+\\.${BASE_DEV_DOMAIN}$`);

  return pattern.test(domain);
};

export const isValidUrl = (url: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-{}]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator

  return pattern.test(url);
};
