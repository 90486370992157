import * as React from 'react';
import SuccessButton from './buttons/SuccessButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const ScrollButtons = () => {
  function getMaxScrollPosition() {
    return Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
    );
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '30px',
        right: '30px',
        backgroundColor: 'gainsboro',
        borderRadius: '10px',
        padding: '10px 0',
      }}
    >
      <SuccessButton
        size="small"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        <KeyboardArrowUp />
      </SuccessButton>
      <SuccessButton
        size="small"
        onClick={() => {
          window.scrollTo({ top: getMaxScrollPosition(), behavior: 'smooth' });
        }}
      >
        <KeyboardArrowDown />
      </SuccessButton>
    </div>
  );
};

export default ScrollButtons;
