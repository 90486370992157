import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentProps } from 'react-admin';
import { WebpushTpl } from '../../model-types';
import WebpushTplForm from './WebpushTplForm';

interface WebpushTplProps {
  record?: WebpushTpl;
}

const WebpushTplTitle: FC<WebpushTplProps> = ({ record }) =>
  record ? <span>Template #{record.id}</span> : null;
const WebpushTplEdit: FC<ResourceComponentProps> = (props) => {
  return (
    <Edit title={<WebpushTplTitle />} {...props}>
      <WebpushTplForm />
    </Edit>
  );
};

export default WebpushTplEdit;
