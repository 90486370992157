import { MarketingFlow } from '../../model-types';

export const validationForm = (marketingFlow: MarketingFlow) => {
  const errors: any = {};

  if (!marketingFlow.segment) {
    errors.segment = ['Segment is required'];
  }
  if (!marketingFlow.tds_path_id) {
    errors.tds_path_id = ['Tds Path is required'];
  }

  return errors;
};
