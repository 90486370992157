import * as React from 'react';
import { FC } from 'react';
import {
  SimpleForm,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { Segment, segmentChoices } from './field-choices';
import { MarketingFlow } from '../../model-types';
import { Toolbar } from 'react-admin';
import { validationForm } from './validation';
import { TextField, TextInput } from 'react-admin';

interface MarketingFlowProps {
  record?: MarketingFlow;
}

const MarketingFlowForm: FC<MarketingFlowProps> = ({ record, ...rest }) => {
  return (
    <SimpleForm
      redirect="list"
      record={record}
      toolbar={<Toolbar alwaysEnableSaveButton />}
      validate={validationForm}
      {...rest}
    >
      <TextField source="hash" />
      <TextInput source="description" />
      <SelectInput
        source="segment"
        defaultValue={Segment.All}
        choices={segmentChoices}
      />
      <ReferenceInput
        label="Tds Path"
        source="tds_path_id"
        reference="tds/tds-paths"
        allowEmpty={true}
        filterToQuery={(searchText: any) => ({ name: '%' + searchText + '%' })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default MarketingFlowForm;
