import React, { FC, useState, useEffect } from 'react';
import {
  BooleanInput,
  NumberInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useRedirect,
} from 'react-admin';
import { useNotify } from 'ra-core';
import { httpClient } from 'httpClient';

import CircularProgress from '@material-ui/core/CircularProgress';

import { LegalInfo } from './submodules/LegalInfo';

import {
  ISitesConfigFormProps,
  ILegalChoice,
  ILegalInfo,
  ISiteSetting,
  ILegalPivot,
} from 'types/sites/siteConfig';
import { IChoice } from 'types/global';
import { paymentMethodChoices } from 'model-types';

const SitesConfigForm: FC<ISitesConfigFormProps> = ({ isEdit = false }) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const [mailDomainChoices, setMailDomainChoices] = useState<IChoice[]>([]);
  const [legalCompanyChoices, setLegalCompanyChoices] = useState<
    ILegalChoice[]
  >([]);

  useEffect(() => {
    httpClient.get<IChoice[]>('/mail-domain').then(({ data }) => {
      setMailDomainChoices(data);
    });

    httpClient.get<ILegalInfo[]>('/legal-info').then(({ data }) => {
      const formattedLegalInfo = data.map((legal: ILegalInfo) => {
        return { id: legal.company, name: legal.company, legal_id: legal.id };
      });
      setLegalCompanyChoices(formattedLegalInfo);
    });
  }, []);

  const checkLegalInfo = (legalInfo: ILegalPivot[]) => {
    const usedGeo = new Set<string>();
    const usedLegalCompany = new Set<number>();

    for (const info of legalInfo) {
      if (!info.geo || !info.legal_id) continue;

      if (usedLegalCompany.has(info.legal_id)) {
        notify('this company already in use', 'error');
        return false;
      }

      if (info.geo.some((geo) => usedGeo.has(geo))) {
        notify('this geo already in use', 'error');
        return false;
      }

      usedLegalCompany.add(info.legal_id);
      info.geo.forEach((geo) => usedGeo.add(geo));
    }

    return true;
  };

  const onSave = (siteSetting: ISiteSetting) => {
    const legalInfo = siteSetting.legal_info?.map((legal: ILegalInfo) => {
      return {
        legal_id: legalCompanyChoices.find(
          (choice: ILegalChoice) => choice.name === legal.company,
        )?.legal_id,
        geo: legal?.pivot?.geo,
      };
    });

    if (!checkLegalInfo(legalInfo)) return;

    if (isEdit) {
      httpClient
        .put(`/sites/${siteSetting.id}`, {
          id: siteSetting.id,
          front_url: siteSetting.front_url,
          is_clean: siteSetting.is_clean,
          is_real: siteSetting.is_real,
          mail_domain_id: siteSetting.mail_domain_id,
          payment_methods: siteSetting.payment_methods,
          name: siteSetting.name,
          site_group_id: siteSetting.site_group_id,
          legal: legalInfo,
        })
        .then(() => redirect('/sites'))
        .catch((error) => {
          Object.keys(error.response.data).forEach((errorKey) =>
            notify(error.response.data[errorKey], 'error', { multiLine: true }),
          );
        });

      return;
    }

    httpClient
      .post(`/sites`, {
        site_id: siteSetting.site_id,
        front_url: siteSetting.front_url,
        is_clean: siteSetting.is_clean,
        is_real: siteSetting.is_real,
        mail_domain_id: siteSetting.mail_domain_id,
        payment_methods: siteSetting.payment_methods,
        name: siteSetting.name,
        site_group_id: siteSetting.site_group_id,
        legal: legalInfo,
      })
      .then(() => redirect('/sites'))
      .catch((error) => {
        Object.keys(error.response.data).forEach((errorKey) =>
          notify(error.response.data[errorKey], 'error', { multiLine: true }),
        );
      });
  };

  return (
    <SimpleForm
      save={onSave}
      toolbar={
        <Toolbar alwaysEnableSaveButton>
          <SaveButton />
        </Toolbar>
      }
    >
      <NumberInput
        source="site_id"
        label="Site id"
        disabled={isEdit}
        validate={required()}
      />
      <NumberInput
        source="site_group_id"
        disabled={isEdit}
        validate={required()}
      />
      <TextInput
        source="front_url"
        label="Domain"
        disabled={isEdit}
        validate={required()}
      />
      <TextInput source="name" validate={required()} />
      <SelectArrayInput
        source="payment_methods"
        label="Payment methods"
        defaultValue={null}
        choices={paymentMethodChoices}
        validate={required()}
      />
      {mailDomainChoices.length ? (
        <SelectInput
          source="mail_domain_id"
          defaultValue={null}
          choices={mailDomainChoices}
          validate={required()}
        />
      ) : (
        <div>
          <CircularProgress color="inherit" />
        </div>
      )}
      <BooleanInput source="is_real" defaultValue={false} />
      <BooleanInput source="is_clean" defaultValue={false} />
      <LegalInfo legalCompanyChoices={legalCompanyChoices} isEdit={isEdit} />
    </SimpleForm>
  );
};

export default SitesConfigForm;
