import React, { useCallback, useEffect, useState } from 'react';
import {
  Datagrid,
  List,
  TextField,
  ResourceComponentProps,
  BooleanField,
  Filter,
  NumberInput,
  TextInput,
  NullableBooleanInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';

import { ChoicesApi } from 'api/ChoicesApi';

import DuplicateBtn from 'layout/buttons/DuplicateBtn';

import { countryChoicesWithoutId } from 'countries';
import { siteGroupIdChoices } from 'model-types';
import { IChoice } from 'types/global';
import { Campaigns } from 'types/packages/packages';

const CountriesField = ({
  record,
}: {
  record?: Campaigns;
  source?: string;
  label?: string;
}) => <span>{record?.countries?.join(', ')}</span>;

const SiteGroupIdsField = ({
  record,
}: {
  record?: Campaigns;
  source?: string;
  label?: string;
}) => <span>{record?.site_group_ids?.join(', ')}</span>;

const CampaignsFilter = ({
  trialChoices,
  ...props
}: {
  trialChoices: IChoice[];
}) => {
  return (
    <Filter {...props}>
      <NumberInput source="id" label="Id" style={{ width: '120px' }} alwaysOn />
      <TextInput
        source="name"
        label="Name"
        style={{ width: '120px' }}
        alwaysOn
      />
      <AutocompleteInput
        label="Country"
        source="countries"
        choices={countryChoicesWithoutId}
        alwaysOn
      />
      <SelectInput
        label="Site group id"
        source="site_group_ids"
        choices={siteGroupIdChoices}
        alwaysOn
      />
      <SelectInput
        source="trial"
        choices={trialChoices}
        loading={trialChoices.length === 0}
        label="Trial"
        style={{ width: '120px' }}
        alwaysOn
      />
      <NullableBooleanInput label="Is active" source="is_active" alwaysOn />
      <NullableBooleanInput
        label="With upsales"
        source="with_upsales"
        alwaysOn
      />
    </Filter>
  );
};

const CampaignsList = (props: ResourceComponentProps) => {
  const [trialChoices, setTrialChoices] = useState<IChoice[]>([]);

  const setChoices = useCallback(async () => {
    const trial = await ChoicesApi.fetchTrialChoices();

    setTrialChoices(trial);
  }, []);

  useEffect(() => {
    setChoices();
  }, []);

  return (
    <List {...props} filters={<CampaignsFilter trialChoices={trialChoices} />}>
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <DuplicateBtn path="campaigns" />
        <TextField source="name" />
        <CountriesField source="countries" />
        <TextField source="trial" />
        <BooleanField source="is_active" />
        <BooleanField source="with_upsales" />
        <SiteGroupIdsField source="site_group_ids" label="Site group ids" />
      </Datagrid>
    </List>
  );
};
export default CampaignsList;
