import { useState } from 'react';
import { Record, useRedirect } from 'react-admin';
import { httpClient } from 'httpClient';

import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IDuplicateBtn {
  record?: Record;
  path: string;
}

const DuplicateBtn = ({ record, path }: IDuplicateBtn) => {
  const redirect = useRedirect();

  const [isLoading, setIsLoading] = useState(false);

  const handleClickDuplicate = (e: any) => {
    e.stopPropagation();

    setIsLoading(true);

    httpClient.get(`/${path}/duplicate/` + record?.id).then((response: any) => {
      setIsLoading(false);

      redirect(`/${path}/` + response.data.id);
    });
  };

  return (
    <IconButton
      id="duplicate-button"
      onClick={handleClickDuplicate}
      disabled={isLoading}
    >
      {isLoading && <CircularProgress color="inherit" size="20px" />}
      {!isLoading && <FileCopyIcon fontSize="small" />}
    </IconButton>
  );
};

export default DuplicateBtn;
