import * as React from 'react';
import { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import PartnerForm from './PartnerForm';

const PartnerCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Partner" {...props}>
      <PartnerForm />
    </Create>
  );
};

export default PartnerCreate;
