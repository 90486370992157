import React, { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import PackagesForm from './PackagesForm';

const PackagesCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Text Category" {...props}>
      <PackagesForm />
    </Create>
  );
};
export default PackagesCreate;
