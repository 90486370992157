import React, { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import PackagesForm from './PackagesForm';

const PackagesEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit {...props}>
      <PackagesForm />
    </Edit>
  );
};
export default PackagesEdit;
