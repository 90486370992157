export enum Segment {
  All = 'all',
  New = 'new',
  ExistingNonReg = 'existing_non_reg',
  ExistingReg = 'existing_reg',
  ExistingPaid = 'existing_paid',
}

export const segmentChoices = [
  { id: null, name: '< Choose one >' },
  { id: Segment.All, name: 'ALL' },
  { id: Segment.New, name: 'NEW' },
  { id: Segment.ExistingNonReg, name: 'Existing Non Reg' },
  { id: Segment.ExistingReg, name: 'Existing Reg' },
  { id: Segment.ExistingPaid, name: 'Existing Paid' },
];
