import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  Filter,
  NumberInput,
  TextInput,
  BooleanField,
  Toolbar,
  useListContext,
  ResourceComponentProps,
} from 'react-admin';
import IconField from '../layout/fields/IconField';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import SuccessButton from '../layout/buttons/SuccessButton';

const UserFilter = (props: any) => (
  <Filter {...props} style={{ maxWidth: '1000px' }}>
    <NumberInput source="id" label="Id" alwaysOn style={{ width: '120px' }} />
    <TextInput
      source="email"
      alwaysOn
      label="Email"
      style={{ width: '120px' }}
    />
    <TextInput
      source="username"
      alwaysOn
      label="Username"
      style={{ width: '120px' }}
    />
  </Filter>
);

const UserPagination = () => {
  const { ids, page, perPage, setPage } = useListContext();
  if (page === 1 && (!ids || ids.length < perPage)) {
    return <></>;
  }
  return (
    <Toolbar>
      {page > 1 && (
        <SuccessButton key="prev" onClick={() => setPage(page - 1)}>
          <ChevronLeft />
          Prev
        </SuccessButton>
      )}
      {ids.length === perPage && (
        <SuccessButton key="next" onClick={() => setPage(page + 1)}>
          Next
          <ChevronRight />
        </SuccessButton>
      )}
    </Toolbar>
  );
};

const UserList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<UserFilter />}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<UserPagination />}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />
        <TextField source="email" />
        <NumberField source="site_id" sortable={false} />
        <TextField source="profile_type" sortable={false} />
        <NumberField source="age" sortable={false} />
        <TextField source="username" />
        <BooleanField source="is_deleted" sortable={false} />
        <TextField source="moderation_status" sortable={false} />
        <BooleanField source="is_premium" sortable={false} />
        <BooleanField source="is_platinum" sortable={false} />
        <BooleanField source="is_vip" sortable={false} />
        <BooleanField source="is_chb" sortable={false} />
        <NumberField source="credits" sortable={false} />
        <IconField source="thumbnail" label="Thumbnail" sortable={false} />
        <TextField source="photo_count" sortable={false} />
        <TextField source="city" sortable={false} />
        <TextField source="country" sortable={false} />
        <DateField
          source="created_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default UserList;
