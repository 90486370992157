import * as React from 'react';
import { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import LegalInfoForm from './LegalInfoForm';

const LegalInfoCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Partner" {...props}>
      <LegalInfoForm />
    </Create>
  );
};

export default LegalInfoCreate;
