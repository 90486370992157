import {
  fetchUtils,
  UpdateParams,
  CreateParams,
  UpdateResult,
  CreateResult,
  HttpError,
} from 'ra-core';
import simpleRestProvider from 'ra-data-simple-rest';
import Cookies from 'js-cookie';

import { getApiUrl, getEnvByHostname } from './utils';

const currentHostname = window.location.hostname;
const env = getEnvByHostname(currentHostname);
const baseApiUrl = getApiUrl(currentHostname, env);

const simpleRestClient = (url: any, options: fetchUtils.Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
    });
  }
  if (options.headers instanceof Headers) {
    let csrfToken = Cookies.get('XSRF-TOKEN');
    if (csrfToken) {
      options.headers.append('X-Csrf-Token', csrfToken);
    }
  }
  options.credentials = 'include';

  return fetchUtils.fetchJson(url, options).catch((err: HttpError) => {
    let message = err.body;
    if (err.status === 422) {
      message = getValidationError(err.body);
    }
    return Promise.reject(new HttpError(message, err.status, err.body));
  });
};

const getValidationError = (body: any): any => {
  if (!body) {
    return body;
  }
  if (body.error) {
    return body.error;
  }
  let error = '';
  for (let key in body) {
    if (Array.isArray(body[key])) {
      error += body[key].join('. ');
    } else {
      error += body[key];
    }
    error += '. ';
  }
  return error;
};

const baseProvider = simpleRestProvider(
  `${baseApiUrl}/admin`,
  simpleRestClient,
  'X-Total-Count',
);

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const restProvider = {
  ...baseProvider,
  update: (
    resource: string,
    params: UpdateParams,
  ): Promise<UpdateResult<any>> => {
    if (!params.data.pictures) {
      // fallback to the default implementation
      return baseProvider.update(resource, params);
    }
    const pictures = params.data.pictures;

    return Promise.all(
      Object.keys(pictures).map(function (key: any) {
        const file = pictures[key];
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve([key, reader.result]);
          reader.onerror = reject;

          reader.readAsDataURL(file.rawFile);
        });
      }),
    ).then((transformedNewPictures) =>
      baseProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          pictures: [...transformedNewPictures],
        },
      }),
    );
  },
  create: (
    resource: string,
    params: CreateParams,
  ): Promise<CreateResult<any>> => {
    if (!params.data.pictures) {
      // fallback to the default implementation
      return baseProvider.create(resource, params);
    }
    const pictures = params.data.pictures;

    return Promise.all(
      Object.keys(pictures).map(function (key: any) {
        const file = pictures[key];
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve([key, reader.result]);
          reader.onerror = reject;

          reader.readAsDataURL(file.rawFile);
        });
      }),
    ).then((transformedNewPictures) =>
      baseProvider.create(resource, {
        ...params,
        data: {
          ...params.data,
          pictures: [...transformedNewPictures],
        },
      }),
    );
  },
};

export default restProvider;
