import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  select: {
    width: '130px',
    marginLeft: '5px',
    padding: '6px 12px 6px 6px',
    border: 0,
    outline: 0,
    font: 'inherit',
    background: `url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat right 0.8em center / 1.4em, linear-gradient(to left, rgba(255, 255, 255, 0.3) 3em, rgba(255, 255, 255, 0.2) 3em)`,
    borderRadius: '0.25em',
    boxShadow: '0 0 1em 0 rgba(0, 0, 0, 0.2)',
    verticalAlign: 'middle',
    cursor: 'pointer',
    appearance: 'none',
  },
});

interface Props {
  initialValue?: any;
  choices: { id: number; label: string; value: any }[];
  onChange: (newValue: any) => void;
}

const CustomSelect = ({ initialValue, choices, onChange }: Props) => {
  const classes = useStyles();

  const handleSelect = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <select
      className={classes.select}
      onChange={handleSelect}
      defaultValue={initialValue}
    >
      {choices.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default CustomSelect;
