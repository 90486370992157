import React from 'react';
import {
  Datagrid,
  List,
  NumberField,
  ResourceComponentProps,
  TextField,
} from 'react-admin';

const MailDomainsList = (props: ResourceComponentProps) => {
  return (
    <List perPage={25} {...props}>
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="created_at" />
      </Datagrid>
    </List>
  );
};

export default MailDomainsList;
