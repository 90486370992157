import MailDomainsIcon from '@material-ui/icons/Mail';

import MailDomainsList from './MailDomainsList';
import MailDomainsEdit from './MailDomainsEdit';
import MailDomainsCreate from './MailDomainsCreate';

export default {
  list: MailDomainsList,
  edit: MailDomainsEdit,
  icon: MailDomainsIcon,
  create: MailDomainsCreate,
};
