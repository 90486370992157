import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[200]),
    backgroundColor: grey[200],
    '&:hover': {
      backgroundColor: grey[400],
    },
    margin: '10px 10px',
  },
}));

const GreyButton = (props: any) => {
  const classes = useStyles();
  return <Button variant="contained" {...props} classes={classes} />;
};

export default GreyButton;
