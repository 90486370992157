import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  TextField,
  Filter,
  TextInput,
  ReferenceField,
  ReferenceInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  ResourceComponentProps,
} from 'react-admin';
import TreeSelectInput from '../../layout/input/TreeSelectInput';
import { cloneElement } from 'react';
import ShortTextField from '../../layout/fields/ShortTextField';

const TextSourceFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput
      label="Parent category"
      source="category_id"
      reference="translations/text-categories"
      alwaysOn
    >
      <TreeSelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="key" />
    <TextInput source="value" />
  </Filter>
);

const ListActions = (props: any) => {
  const categoryId = props.filterValues.category_id;
  const createPath =
    '/translations/text-sources/create' +
    (categoryId ? '?category_id=' + categoryId : '');
  return (
    <TopToolbar>
      {cloneElement(props.filters, { context: 'button' })}
      <CreateButton to={createPath} />
      <ExportButton />
    </TopToolbar>
  );
};

const TextSourceList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<TextSourceFilter />}
      perPage={25}
      title="Text sources"
      actions={<ListActions />}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="key" sortable={false} />
        <ShortTextField source="value" sortable={false} />
        <ReferenceField
          label="Category"
          source="category_id"
          reference="translations/text-categories"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="id" />
        <DateField source="created_at" locales="ru-RU" showTime />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};
export default TextSourceList;
