import { FC } from 'react';
import { AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';
import { Grid } from '@material-ui/core';
import * as React from 'react';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const positiveCss = {
  inputRoot: {
    '&,&:hover,&:focus-within': {
      backgroundColor: green[100],
    },
  },
};
const usePositiveStyles = makeStyles((theme) => positiveCss);
const useMutedPositiveStyles = makeStyles((theme) => ({
  ...positiveCss,
  container: {
    opacity: 0.3,
  },
}));

const negativeCss = {
  inputRoot: {
    '&,&:hover,&:focus-within': {
      backgroundColor: red[100],
    },
  },
};
const useNegativeStyles = makeStyles((theme) => negativeCss);
const useMutedNegativeStyles = makeStyles((theme) => ({
  ...negativeCss,
  container: {
    opacity: 0.3,
  },
}));

interface OfferFilterProps {
  source: string;
  label: string;
  reference?: string | null;
  choices?: object[];
  isPositive: boolean;
  isMuted: boolean;
  validate?: object[];
}

const OfferFilterAutocompleteInput: FC<OfferFilterProps> = ({
  source,
  label,
  reference = null,
  choices,
  isMuted,
  isPositive,
  validate = [],
}) => {
  const classes = isPositive
    ? isMuted
      ? useMutedPositiveStyles()
      : usePositiveStyles()
    : isMuted
    ? useMutedNegativeStyles()
    : useNegativeStyles();
  const inputLabel = (isPositive ? 'Include ' : 'Exclude ') + label;

  return (
    <Grid item xs={6}>
      {reference ? (
        <ReferenceArrayInput
          reference={reference}
          label={inputLabel}
          allowEmpty={false}
          filterToQuery={(searchText: any) =>
            searchText ? { id: searchText } : null
          }
          source={source}
          alwaysOn
          style={{ width: '100%' }}
        >
          <AutocompleteArrayInput
            optionText={(choice: any) => choice && `${choice.id}`}
            classes={classes}
            validate={validate}
          />
        </ReferenceArrayInput>
      ) : (
        <AutocompleteArrayInput
          classes={classes}
          validate={validate}
          choices={choices}
          source={source}
          label={inputLabel}
        />
      )}
    </Grid>
  );
};
export default OfferFilterAutocompleteInput;
