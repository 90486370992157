import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { TextCategory } from '../../model-types';
import TextCategoryForm from './TextCategoryForm';

interface TextCategoryProps {
  record?: TextCategory;
}

const TextCategoryTitle: FC<TextCategoryProps> = ({ record }) =>
  record ? <span>Text Category #{record.id}</span> : null;

const TextCategoryEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<TextCategoryTitle />} {...props}>
      <TextCategoryForm />
    </Edit>
  );
};
export default TextCategoryEdit;
