import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, Grid, Chip } from '@material-ui/core';
import {
  ThumbUp,
  RemoveCircle,
  Delete,
  SkipNext,
  RotateRight,
} from '@material-ui/icons';
import { Title, useAuthenticated, useNotify } from 'react-admin';
import { httpClient } from '../httpClient';
import { AxiosResponse } from 'axios';
import SuccessButton from '../layout/buttons/SuccessButton';
import ErrorButton from '../layout/buttons/ErrorButton';
import GreyButton from '../layout/buttons/GreyButton';
import ImageGridList from './ImageGridList';
import { useHistory, useParams } from 'react-router-dom';
import { UserShort, UserPhoto, UserAdditionalInfo } from '../model-types';
import Gender from '../layout/Gender';

import { EventEmitter } from 'eventEmitter';

import { EmitterEvents } from 'types/enums/EmitterEvents';

const BotPhotoModeration = () => {
  useAuthenticated();

  const history = useHistory();
  const notify = useNotify();

  let { initPhotoId }: any = useParams();
  initPhotoId = initPhotoId ? parseInt(initPhotoId) : null;

  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState<UserPhoto | null>(null);
  const [photoId, setPhotoId] = useState<number | null>(initPhotoId);
  const [tiles, setTiles] = useState<UserPhoto[]>([]);
  const [user, setUser] = useState<UserShort | null>(null);
  const [userAdditionalInfo, setUserAdditionalInfo] =
    useState<UserAdditionalInfo | null>(null);
  const [unModeratedCount, setUnModeratedCount] = useState<number | null>(null);
  const [lastModeratedAt, setLastModeratedAt] = useState<Date | null>(null);

  const getPhoto = (getId = photoId) => {
    httpClient
      .get('/photo/moderation' + (getId ? '/' + getId : '/bot'))
      .then(changePhoto)
      .catch(function () {});
  };

  const changePhoto = (response: AxiosResponse) => {
    const photoId = response.data.photo?.id ?? null;
    const newUrl = '/photo/moderation' + (photoId ? '/' + photoId : '/bot');

    if (!document.URL.endsWith(newUrl)) {
      history.push(newUrl);
    }

    setPhotoId(photoId);
    setUser(response.data.user ?? null);
    setTiles(response.data.photos ?? []);
    setPhoto(response.data.photo ?? null);
    setUnModeratedCount(response.data.unmoderated_count);
    setLastModeratedAt(new Date(response.data.last_moderated_at));

    setIsLoading(false);
  };

  const changePhotoWithCounters = (response: AxiosResponse) => {
    changePhoto(response);
    EventEmitter.dispatch(EmitterEvents.ReloadModerationCounters);
  };

  const changeAdditionalUserInfo = (response: AxiosResponse) => {
    if (response.data) setUserAdditionalInfo(response.data ?? null);
  };

  const handleError = (err: any) => {
    notify(
      err?.message || 'Oops, something went wrong. Reload page and try again!',
    );

    setIsLoading(false);
  };

  const approve = () => {
    if (!photo || isLoading) return;

    setIsLoading(false);

    httpClient
      .get('/photo/moderation/approve/' + photo.id)
      .then(changePhotoWithCounters)
      .catch(handleError);
  };

  const ban = () => {
    if (!photo || isLoading) return;

    setIsLoading(false);

    httpClient
      .get('/photo/moderation/ban/' + photo.id)
      .then(changePhotoWithCounters)
      .catch(handleError);
  };

  const remove = () => {
    if (!photo || isLoading) return;

    setIsLoading(false);

    httpClient
      .get('/photo/moderation/delete/' + photo.id)
      .then(changePhotoWithCounters)
      .catch(handleError);
  };

  const skip = () => {
    if (!photo || isLoading) return;

    setIsLoading(false);

    httpClient
      .get('/photo/moderation/skip/' + photo.id)
      .then(changePhoto)
      .catch(handleError);
  };

  const rotate = () => {
    if (!photo) return;

    httpClient
      .get('/photo/moderation/rotate/' + photo.id)
      .then(changePhoto)
      .catch(handleError);
  };

  const getAdditionalUserInfo = useCallback(() => {
    if (user?.id) {
      httpClient
        .get(`/users/specific/${user.id}`)
        .then(changeAdditionalUserInfo);
    }
  }, [user?.id]);

  useEffect(() => {
    if (
      initPhotoId &&
      initPhotoId !== photoId &&
      document.URL.endsWith('/photo/moderation/' + initPhotoId)
    ) {
      getPhoto(initPhotoId);
    }
  }, [initPhotoId, photoId]);

  useEffect(getPhoto, [photoId]);

  useEffect(() => {
    EventEmitter.subscribe(EmitterEvents.PhotoModerationReload, () => {
      if (!photo) getPhoto();
    });

    return () => {
      EventEmitter.unsubscribe(EmitterEvents.PhotoModerationReload);
    };
  }, [photo]);

  useEffect(getAdditionalUserInfo, [getAdditionalUserInfo]);

  return (
    <Card>
      <Title title="Bot Photo Moderation" />
      <CardContent>
        {photo && user && (
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <SuccessButton
                endIcon={<ThumbUp />}
                onClick={approve}
                disabled={isLoading}
              >
                Approve
              </SuccessButton>
              <GreyButton endIcon={<RotateRight />} onClick={rotate}>
                Rotate
              </GreyButton>
              <ErrorButton
                endIcon={<RemoveCircle />}
                onClick={ban}
                disabled={isLoading}
              >
                Ban
              </ErrorButton>
              <ErrorButton
                endIcon={<Delete />}
                onClick={remove}
                disabled={isLoading}
              >
                Delete
              </ErrorButton>
              <GreyButton
                endIcon={<SkipNext />}
                onClick={skip}
                disabled={isLoading}
              >
                Skip
              </GreyButton>
            </Grid>
            <Grid item xs={4}>
              <Chip
                label={unModeratedCount + ' remains'}
                variant="outlined"
                style={{ margin: '5px' }}
              />
              <Chip
                label={'last moderation: ' + lastModeratedAt?.toLocaleString()}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              #{user.id} <Gender type={user.gender} />, Country:{' '}
              {userAdditionalInfo?.country_name || 'unknown'}, Host:{' '}
              {userAdditionalInfo?.host || 'unknown'}
            </Grid>
            <Grid item xs={8}>
              {photo.is_main ? 'Profile' : 'Public'}
              <img
                src={photo.big_url}
                alt={'#' + user.id}
                style={{ maxWidth: '100%' }}
              />
            </Grid>
            <Grid item xs={4}>
              <ImageGridList tiles={tiles} />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
export default BotPhotoModeration;
