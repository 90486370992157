import { AutocompleteInput, ReferenceInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import TextSourceQuickCreateButton, {
  TextSourceQuickCreateButtonProps,
} from './TextSourceQuickCreateButton';
import { ReferenceInputProps } from 'ra-ui-materialui/esm/input/ReferenceInput';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

type TextSourceReferenceInputProps = Omit<ReferenceInputProps, 'children'> &
  TextSourceQuickCreateButtonProps;

const TextSourceReferenceInput = (props: TextSourceReferenceInputProps) => {
  const classes = useStyles();
  let { className, ...rest } = props; // delete className

  return (
    <div className={classes.root}>
      {/* @ts-ignore */}
      <ReferenceInput
        filterToQuery={(searchText: any) => ({ value: '%' + searchText + '%' })}
        validate={required()}
        alwaysOn
        perPage={1000}
        fullWidth
        multiline
        {...rest}
      >
        <AutocompleteInput optionText="value" fullWidth multiline />
      </ReferenceInput>

      <TextSourceQuickCreateButton {...rest} />
    </div>
  );
};

export default TextSourceReferenceInput;
