export const platformChoices = [
  { id: 3, name: 'mobile' },
  { id: 1, name: 'desktop' },
  { id: 2, name: 'tablet' },
  { id: 4, name: 'other' },
];

export const deviceOsChoices = [
  { id: 1, name: 'ios' },
  { id: 2, name: 'android' },
  { id: 3, name: 'windows' },
  { id: 4, name: 'other' },
];
