import MarketingFlowIcon from '@material-ui/icons/SwapCalls';

import MarketingFlowList from './MarketingFlowList';
import MarketingFlowEdit from './MarketingFlowEdit';
import MarketingFlowCreate from './MarketingFlowCreate';

export default {
  list: MarketingFlowList,
  edit: MarketingFlowEdit,
  create: MarketingFlowCreate,
  icon: MarketingFlowIcon,
};
