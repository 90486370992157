import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  Filter,
  NumberInput,
  ResourceComponentProps,
} from 'react-admin';

const GroupFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" alwaysOn />
  </Filter>
);

const GroupList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<GroupFilter />}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="site_group_ids" label="Site group ids" />
        <TextField source="qualification_reasons" />
        <DateField source="created_at" locales="ru-RU" showTime />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};

export default GroupList;
