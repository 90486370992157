import * as React from 'react';
import { Route } from 'react-router-dom';
import UserPhotoModeration from './photoModeration/UserPhotoModeration';
import BotPhotoModeration from './photoModeration/BotPhotoModeration';

export default [
  <Route
    exact
    path="/photo/moderation/:initPhotoId(\d+)"
    component={UserPhotoModeration}
  />,
  <Route exact path="/photo/moderation/user" component={UserPhotoModeration} />,
  <Route exact path="/photo/moderation/bot" component={BotPhotoModeration} />,
];
