import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  Filter,
  NumberInput,
  SelectInput,
  ReferenceField,
  AutocompleteInput,
  ReferenceInput,
  ResourceComponentProps,
} from 'react-admin';
import { segmentChoices } from './field-choices';

const MarketingFlowFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" alwaysOn />
    <SelectInput source="segment" choices={segmentChoices} alwaysOn />
    <ReferenceInput
      label="Tds Path"
      source="tds_path_id"
      reference="tds/tds-paths"
      allowEmpty={true}
      filterToQuery={(searchText: any) => ({ name: '%' + searchText + '%' })}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const MarketingFlowList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<MarketingFlowFilter />}
      perPage={25}
      sort={{ field: 'updated_at', order: 'DESC' }}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />
        <TextField source="hash" />
        <TextField source="segment" />
        <ReferenceField
          label="Tds Path"
          source="tds_path_id"
          reference="tds/tds-paths"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" />
        <DateField source="created_at" locales="ru-RU" showTime />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};

export default MarketingFlowList;
