import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  ReferenceInput,
  required,
  ResourceComponentPropsWithId,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { TextSource, textTranslationsActions } from '../../model-types';
import TreeSelectInput from '../../layout/input/TreeSelectInput';
import CopyClipboardInput from '../../layout/input/CopyClipboardInput';

interface TextSourceProps {
  record?: TextSource;
}

const TextSourceTitle: FC<TextSourceProps> = ({ record }) =>
  record ? <span>Text Source #{record.id}</span> : null;

const TextSourceEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<TextSourceTitle />} {...props}>
      <SimpleForm redirect="list">
        <ReferenceInput
          label="Category"
          source="category_id"
          reference="translations/text-categories"
          alwaysOn
          validate={[required()]}
        >
          <TreeSelectInput optionText="name" checkTree={false} />
        </ReferenceInput>
        <TextInput source="key" disabled={true} />
        <CopyClipboardInput source="full_key" disabled={true} />
        <TextInput source="value" multiline style={{ width: '100%' }} />
        <SelectInput
          source="action"
          choices={textTranslationsActions}
          label="Do with translations"
          defaultValue="disapprove"
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};
export default TextSourceEdit;
