import * as React from 'react';
import { Datagrid, List, ResourceComponentProps, TextField } from 'react-admin';

const SystemSettingsList = (props: ResourceComponentProps) => {
  return (
    <List {...props}>
      <Datagrid optimized rowClick="edit">
        <TextField source="id" label="Key" />
        <TextField source="value" />
      </Datagrid>
    </List>
  );
};

export default SystemSettingsList;
