import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import SitesConfigForm from './SitesConfigForm';

const SystemSettingsEdit: FC<ResourceComponentPropsWithId> = ({
  basePath,
  ...props
}) => {
  return (
    <Edit title="Edit site config" basePath={basePath} {...props}>
      <SitesConfigForm isEdit={true} />
    </Edit>
  );
};

export default SystemSettingsEdit;
