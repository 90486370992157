import * as React from 'react';
import { Add } from '@material-ui/icons';
import _ from 'lodash';

import { PostbackEventConfig } from 'model-types';

import SuccessButton from 'layout/buttons/SuccessButton';

interface Props {
  index: number;
  config: PostbackEventConfig;
  isAddVisitEnabled?: boolean;
  onDuplicate: (index: number, config: PostbackEventConfig) => void;
  onAddVisit: (index: number, config: PostbackEventConfig) => void;
}

export const PostbackEventConfigsActions: React.FC<Props> = React.memo(
  ({ index, config, isAddVisitEnabled, onDuplicate, onAddVisit }) => {
    return (
      <div style={{ marginBottom: '15px' }}>
        <SuccessButton
          startIcon={<Add />}
          size="small"
          onClick={() => onDuplicate(index, config)}
        >
          Duplicate
        </SuccessButton>
        {isAddVisitEnabled && (
          <SuccessButton
            startIcon={<Add />}
            size="small"
            onClick={() => onAddVisit(index, config)}
          >
            Add visit
          </SuccessButton>
        )}
      </div>
    );
  },
);
