import { TdsPath } from '../../model-types';
import { Direction } from './field-choices';

export const validationForm = (tdsPath: TdsPath) => {
  const errors: any = {};

  if (!tdsPath.name) {
    errors.name = ['Name is required'];
  }

  if (!tdsPath.host) {
    errors.host = ['Host is required'];
  }
  if (!tdsPath.direction) {
    errors.direction = ['Direction is required'];
  }
  if (
    tdsPath.direction &&
    tdsPath.direction === Direction.Landing &&
    (!tdsPath.landing_number || tdsPath.landing_number <= 0)
  ) {
    errors.landing_number = [
      'Landing number is required with selected direction',
    ];
  }

  return errors;
};
