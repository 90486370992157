import LegalInfoIcon from '@material-ui/icons/Assignment';

import LegalInfoList from './LegalInfoList';
import LegalInfoEdit from './LegalInfoEdit';
import LegalInfoCreate from './LegalInfoCreate';

export default {
  list: LegalInfoList,
  edit: LegalInfoEdit,
  icon: LegalInfoIcon,
  create: LegalInfoCreate,
};
