import * as React from 'react';
import { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import WebpushTplForm from './WebpushTplForm';

const WebpushTplCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Template" {...props}>
      <WebpushTplForm />
    </Create>
  );
};

export default WebpushTplCreate;
