import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { PartnerNetwork } from '../../model-types';
import PartnerNetworkForm from './PartnerNetworkForm';

interface PartnerNetworkProps {
  record?: PartnerNetwork;
}

const PartnerNetworkTitle: FC<PartnerNetworkProps> = ({ record }) =>
  record ? <span>Partner Network #{record.id}</span> : null;

const PartnerNetworkEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<PartnerNetworkTitle />} {...props}>
      <PartnerNetworkForm />
    </Edit>
  );
};

export default PartnerNetworkEdit;
