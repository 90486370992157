import SitesConfigIcon from '@material-ui/icons/SettingsApplications';

import SitesConfigList from './SitesConfigList';
import SitesConfigEdit from './SitesConfigEdit';
import SitesConfigCreate from './SitesConfigCreate';

export default {
  list: SitesConfigList,
  edit: SitesConfigEdit,
  icon: SitesConfigIcon,
  create: SitesConfigCreate,
};
