import * as React from 'react';
import { Record, Link, TextField } from 'react-admin';
import { get } from 'lodash';

interface ILinkField {
  path: string;
  sourceName: string;
  sourceLabel?: string;
  label?: string;
  cellClassName?: string;
  record?: Record;
}

const LinkField = ({ record, path, sourceName, sourceLabel }: ILinkField) => {
  if (!record) return null;

  const value = get(record, sourceName);
  const label = sourceLabel ? sourceLabel : sourceName;

  return (
    <Link to={`/${path}/${value}`}>
      <TextField source={label} />
    </Link>
  );
};

export default LinkField;
