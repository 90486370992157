import { siteGroupIdChoices, TransferGroup } from 'model-types';
import { FC } from 'react';
import { required, SelectArrayInput, SimpleForm, TextInput } from 'react-admin';
import * as React from 'react';
import { qualificationReasonsChoices } from 'userTransfer/group/filed-choices';

interface GroupProps {
  record?: TransferGroup;
}

const GroupForm: FC<GroupProps> = ({ record, ...rest }) => {
  if (!record) {
    return null;
  }
  return (
    <SimpleForm redirect="list" record={record} {...rest}>
      <TextInput source="name" validate={[required()]} />
      <SelectArrayInput
        source="qualification_reasons"
        choices={qualificationReasonsChoices}
        validate={[required()]}
        style={{ width: '100%' }}
        optionText={(choice: any) => choice && `${choice.id}: ${choice.name}`}
      />
      <SelectArrayInput
        label="Site group ids"
        source="site_group_ids"
        choices={siteGroupIdChoices}
        validate={[required()]}
        style={{ width: '100%' }}
      />
    </SimpleForm>
  );
};
export default GroupForm;
