import { TextCategory } from '../../model-types';
import { FC } from 'react';
import { ReferenceInput, required, SimpleForm, TextInput } from 'react-admin';
import TreeSelectInput from '../../layout/input/TreeSelectInput';
import * as React from 'react';

interface TextCategoryProps {
  record?: TextCategory;
}

const TextCategoryForm: FC<TextCategoryProps> = ({ record, ...rest }) => {
  if (!record) {
    return null;
  }
  return (
    <SimpleForm redirect="list" record={record} {...rest}>
      <TextInput source="name" validate={[required()]} />
      <ReferenceInput
        label="Parent category"
        source="parent_id"
        reference="translations/text-categories-select"
        alwaysOn
        allowEmpty={true}
      >
        <TreeSelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  );
};
export default TextCategoryForm;
