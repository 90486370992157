import { FC, useState } from 'react';
import { SimpleForm, TextInput, useRedirect } from 'react-admin';

import { PartnerNetwork } from '../../model-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { httpClient } from '../../httpClient';
import { useNotify } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  form: {
    position: 'relative',
  },
  loader: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translate(-16px, -16px)',
    backgroundColor: '#00000087',
    color: 'white',
  },
}));

interface PartnerNetworkProps {
  record?: PartnerNetwork;
}

const PartnerNetworkForm: FC<PartnerNetworkProps> = ({ record, ...rest }) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (data: PartnerNetwork) => {
    setIsLoading(true);

    try {
      if (data.id) {
        await httpClient.put(`/partners-networks/${data.id}`, { ...data });
      } else {
        await httpClient.post('/partners-networks', { ...data });
      }
      redirect('/partners-networks/');
    } catch (err) {
      notify(`Data not saved. Error msg: ${err}`, 'warning');
    }
  };

  return (
    <SimpleForm
      {...rest}
      className={classes.form}
      record={record}
      redirect={false}
      save={submitForm}
    >
      {isLoading && (
        <div className={classes.loader}>
          <CircularProgress color="inherit" />
        </div>
      )}
      <TextInput source="name" />
    </SimpleForm>
  );
};

export default PartnerNetworkForm;
