import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  Filter,
  NumberInput,
  TextInput,
  BooleanField,
  ReferenceField,
  AutocompleteInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  NullableBooleanInput,
  ResourceComponentProps,
} from 'react-admin';
import SimpleArrayField from 'layout/fields/SimpleArrayField';
import { deviceOsChoices, platformChoices } from './field-choices';
import { bannerPlacementChoices, costModelChoices } from 'model-types';
import { countryChoices } from 'countries';
import DuplicateBtn from 'layout/buttons/DuplicateBtn';

const OfferFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" label="Id" alwaysOn />
    <ReferenceInput
      label="Group"
      source="group_id"
      reference="user-transfers/groups"
      allowEmpty={true}
      alwaysOn
    >
      <SelectInput
        optionText={(choice: any) => choice.id + ':' + choice.name}
      />
    </ReferenceInput>
    <NullableBooleanInput source="is_active" alwaysOn />
    <NullableBooleanInput source="is_remnant" alwaysOn />
    <NullableBooleanInput source="is_internal" alwaysOn />
    <TextInput source="url" label="URL" alwaysOn />
    <ReferenceInput
      label="Brand"
      source="brand_id"
      reference="user-transfers/brands"
      allowEmpty={true}
      filterToQuery={(searchText: any) => ({ name: '%' + searchText + '%' })}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <SelectInput source="platform" choices={platformChoices} alwaysOn />
    <SelectInput source="device_os" choices={deviceOsChoices} alwaysOn />
    <SelectInput
      source="banner_placement"
      choices={bannerPlacementChoices}
      alwaysOn
    />
    <AutocompleteInput source="country" choices={countryChoices} alwaysOn />
    <SelectInput
      source="cost_model"
      defaultValue={null}
      choices={costModelChoices}
    />
    <NumberInput source="partner" />
  </Filter>
);

const OfferList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<OfferFilter />}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />
        <DuplicateBtn path="user-transfers/offers" />
        <BooleanField source="is_active" />
        <BooleanField source="is_remnant" />
        <TextField source="campaign_id" />
        <NumberField source="weight" />
        <div style={{ maxWidth: '112px', overflow: 'hidden' }}>
          <ReferenceField
            label="Group ID"
            source="group_id"
            reference="user-transfers/groups"
          >
            <FunctionField
              render={(choice: any) => choice.id + ':' + choice.name}
            />
          </ReferenceField>
        </div>
        <BooleanField
          source="view.is_internal"
          label="Is Internal"
          sortable={false}
        />
        <TextField source="url" label="URL" />
        <TextField
          source="for_site_ids"
          emptyText="All"
          label="For&nbsp;users from&nbsp;site&nbsp;IDs"
        />
        <ReferenceField source="brand_id" reference="user-transfers/brands">
          <TextField source="name" />
        </ReferenceField>
        <SimpleArrayField
          source="view.platform"
          label="Platform"
          emptyText="All"
          sortable={false}
        />
        <SimpleArrayField
          source="view.device_os"
          label="Device OS"
          emptyText="All"
          sortable={false}
        />
        <SimpleArrayField
          source="view.banner_placement"
          label="Banner placement"
          emptyText="All"
          sortable={false}
        />
        <SimpleArrayField
          source="view.country"
          label="Country"
          emptyText="All"
          sortable={false}
        />
        <DateField source="created_at" locales="ru-RU" showTime />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};

export default OfferList;
