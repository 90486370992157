import * as React from 'react';
import { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import GroupForm from 'userTransfer/group/GroupForm';

const GroupCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Group" {...props}>
      <GroupForm />
    </Create>
  );
};
export default GroupCreate;
