import React, { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  FormWithRedirect,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { TextSource } from '../../model-types';
import { HttpError } from 'ra-core';

export interface TextSourceQuickCreateButtonProps {
  label: string;
  source: string;
  prefixSource: string;
  reference: string;
  onChange: Function;
}

function TextSourceQuickCreateButton({
  label,
  source,
  prefixSource,
  reference,
  onChange,
}: TextSourceQuickCreateButtonProps) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate(reference);
  const form = useForm();
  const { values } = useFormState();
  const notify = useNotify();
  const title = 'Create Text Source for ' + label;

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (createValues: any) => {
    createValues.key_prefix = values[prefixSource];
    create(
      { payload: { data: createValues } },
      {
        onSuccess: ({ data }: { data: TextSource }) => {
          setShowDialog(false);
          form.change(source, data.id);
          onChange();
        },
        onFailure: (error: HttpError) => {
          notify(error.message, 'error');
        },
      },
    );
  };

  return (
    <>
      <Button onClick={handleClick} label="ra.action.create">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={title}
      >
        <DialogTitle>{title}</DialogTitle>

        <FormWithRedirect
          resource="translations/text-sources"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <>
              <DialogContent>
                <TextInput
                  source="value"
                  validate={required()}
                  label="Value"
                  fullWidth
                  multiline
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default TextSourceQuickCreateButton;
