import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  TextField,
  EditButton,
  ResourceComponentProps,
} from 'react-admin';
import TreeNodeField from '../../layout/fields/TreeNodeField';
import LinkToRelatedSources from './LinkToRelatedSources';

const TextCategoryList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      pagination={false}
      title="Text categories"
    >
      <Datagrid optimized rowClick="">
        <TreeNodeField source="name" sortable={false} />
        <TextField source="id" sortable={false} />
        <DateField
          source="created_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />
        <DateField
          source="updated_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />
        <LinkToRelatedSources />
        <EditButton />
      </Datagrid>
    </List>
  );
};
export default TextCategoryList;
