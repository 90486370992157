import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { TransferBrand } from 'model-types';
import BrandForm from './BrandForm';

interface BrandProps {
  record?: TransferBrand;
}

const BrandTitle: FC<BrandProps> = ({ record }) =>
  record ? <span>Brand #{record.id}</span> : null;

const BrandEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<BrandTitle />} {...props}>
      <BrandForm />
    </Edit>
  );
};
export default BrandEdit;
