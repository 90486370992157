import PackageInfoIcon from '@material-ui/icons/LocalMall';
import PackageInfoList from './PackageInfoList';
import PackageInfoEdit from './PackageInfoEdit';
import PackageInfoCreate from './PackageInfoCreate';

export default {
  list: PackageInfoList,
  edit: PackageInfoEdit,
  create: PackageInfoCreate,
  icon: PackageInfoIcon,
};
