import * as React from 'react';
import { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import TextCategoryForm from './TextCategoryForm';

const TextCategoryCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Text Category" {...props}>
      <TextCategoryForm />
    </Create>
  );
};
export default TextCategoryCreate;
