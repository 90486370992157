import React from 'react';
import { FieldProps } from 'types';
import _ from 'lodash';

type SimpleArrayFieldProps = FieldProps & {
  emptyText?: string;
  style?: object;
};
const SimpleArrayField = ({
  source,
  record,
  emptyText,
  style,
}: SimpleArrayFieldProps) => {
  if (!record || !source) {
    return <></>;
  }
  let value = _.get(record, source);
  let text = !value?.length ? emptyText ?? '' : value.join(', ');

  return <div style={style ?? {}}>{text}</div>;
};
export default SimpleArrayField;
