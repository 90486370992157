import GeoSegmentIcon from '@material-ui/icons/Public';
import GeoSegmentList from './GeoSegmentList';
import GeoSegmentEdit from './GeoSegmentEdit';
import GeoSegmentCreate from './GeoSegmentCreate';

export default {
  list: GeoSegmentList,
  edit: GeoSegmentEdit,
  create: GeoSegmentCreate,
  icon: GeoSegmentIcon,
};
