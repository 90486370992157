import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  TextField,
  EditButton,
  Filter,
  NumberInput,
  TextInput,
  ResourceComponentProps,
} from 'react-admin';

const AffiliateNetworkFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" alwaysOn />
    <TextInput source="name" alwaysOn />
  </Filter>
);

const AffiliateNetworkList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<AffiliateNetworkFilter />}
      perPage={25}
      sort={{ field: 'name', order: 'ASC' }}
      title="Affiliate Networks"
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="created_at" locales="ru-RU" showTime />
        <DateField source="updated_at" locales="ru-RU" showTime />
        <EditButton />
      </Datagrid>
    </List>
  );
};
export default AffiliateNetworkList;
