import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import './App.css';

import { authProvider } from 'authProvider';
import { Login, Layout } from './layout';
import customRoutes from './routes';
import UserPhotoModeration from './photoModeration/UserPhotoModeration';
import VideoModeration from './videoModeration/VideoModeration';
import webpushTpl from './webpush/templates';
import partner from './partner';
import partnerNetworks from './partner/network';
import users from './users';
import transferOffers from './userTransfer/offer';
import transferGroups from './userTransfer/group';
import transferBrands from './userTransfer/brand';
import affiliateNetworks from './userTransfer/affiliateNetwork';
import marketingFlows from './tds/marketingFlow';
import tdsPaths from './tds/tdsPath';
import textCategories from './translations/textCategory';
import textSources from './translations/textSource';
import systemSettings from './systemSettings';
import legalInfo from './sitesConfig/legalInfo';
import sitesConfig from './sitesConfig/sites';
import mailDomains from './sitesConfig/mailDomains';
import restProvider from './restProvider';
import i18nProvider from './i18nPrivider';
import geoSegment from 'userTransfer/geoSegment';
import PhpEnumList from 'docs/PhpEnumList';
import AdsBanners from './adsBanners';
import Packages from 'pages/packages';
import PackageInfo from 'pages/packageInfo';
import Campaigns from 'pages/campaigns';

import 'react-toggle/style.css';

const App = () => (
  <Admin
    title="Hookupers admin"
    dataProvider={restProvider}
    i18nProvider={i18nProvider}
    authProvider={authProvider}
    customRoutes={customRoutes}
    loginPage={Login}
    layout={Layout}
    disableTelemetry
  >
    <Resource name="photo/moderation" list={UserPhotoModeration} />
    <Resource name="video/moderation" list={VideoModeration} />
    <Resource name="users" {...users} />
    <Resource name="webpush/templates" {...webpushTpl} />
    <Resource name="partners" {...partner} />
    <Resource name="partners-networks" {...partnerNetworks} />

    <Resource name="tds/marketing-flows" {...marketingFlows} />
    <Resource name="tds/tds-paths" {...tdsPaths} />

    <Resource name="user-transfers/offers" {...transferOffers} />
    <Resource name="user-transfers/groups" {...transferGroups} />
    <Resource name="user-transfers/brands" {...transferBrands} />
    <Resource name="user-transfers/affiliate-networks" {...affiliateNetworks} />
    <Resource name="user-transfers/geo-segments" {...geoSegment} />

    <Resource name="translations/text-categories" {...textCategories} />
    <Resource name="translations/text-categories-select" />
    <Resource name="translations/text-sources" {...textSources} />
    <Resource name="translations/text-sources/webpush/title" />
    <Resource name="translations/text-sources/webpush/body" />

    <Resource name="system-settings" {...systemSettings} />

    <Resource name="sites" {...sitesConfig} />
    <Resource name="legal-info" {...legalInfo} />
    <Resource name="mail-domain" {...mailDomains} />

    <Resource name="docs/enums" list={PhpEnumList} />
    <Resource name="ads-banners" {...AdsBanners} />

    <Resource name="packages" {...Packages} />
    <Resource name="package-info" {...PackageInfo} />
    <Resource name="campaigns" {...Campaigns} />
  </Admin>
);

export default App;
