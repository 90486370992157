import { TransferGeoSegment } from 'model-types';
import { FC } from 'react';
import {
  AutocompleteArrayInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';
import * as React from 'react';
import { countryChoices } from 'countries';

interface GeoSegmentProps {
  record?: TransferGeoSegment;
}

const GeoSegmentForm: FC<GeoSegmentProps> = ({ record, ...rest }) => {
  if (!record) {
    return null;
  }
  return (
    <SimpleForm redirect="list" record={record} {...rest}>
      <TextInput source="name" validate={[required()]} />
      <AutocompleteArrayInput
        source="countries"
        choices={countryChoices}
        style={{ width: '100%' }}
      />
    </SimpleForm>
  );
};
export default GeoSegmentForm;
