export const convertBlobUrlToBase64 = async (
  blobUrl: string,
): Promise<string> => {
  try {
    const response = await fetch(blobUrl);

    if (!response.ok) {
      throw new Error(`Failed to fetch blob URL: ${response.statusText}`);
    }

    const blob = await response.blob();

    if (!(blob instanceof Blob)) {
      throw new Error('The fetched data is not a valid Blob.');
    }

    const reader = new FileReader();

    return new Promise<string>((resolve, reject) => {
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const base64Data = reader.result.split(',')[1]; // Extract base64 part
          resolve(`data:${blob.type};base64,${base64Data}`);
        } else {
          reject(
            new Error(
              'Failed to convert file to base64. Result is not a string.',
            ),
          );
        }
      };

      reader.onerror = () => {
        reject(new Error('Failed to read the file.'));
      };

      reader.readAsDataURL(blob); // Convert the blob to a base64 data URL
    });
  } catch (error) {
    console.error('Error converting blob URL to base64:', error);
    throw error;
  }
};
