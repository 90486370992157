import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { AffiliateNetwork } from 'model-types';
import AffiliateNetworkForm from './AffiliateNetworkForm';

interface affiliateNetworkProps {
  record?: AffiliateNetwork;
}

const AffiliateNetworkTitle: FC<affiliateNetworkProps> = ({ record }) =>
  record ? <span>Affiliate Network #{record.id}</span> : null;

const AffiliateNetworkEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<AffiliateNetworkTitle />} {...props}>
      <AffiliateNetworkForm />
    </Edit>
  );
};
export default AffiliateNetworkEdit;
