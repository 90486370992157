import { BooleanInput, FormDataConsumer } from 'react-admin';
import * as React from 'react';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import _ from 'lodash';

const useSwitchStyles = makeStyles((theme) => ({
  colorPrimary: {
    color: red[500],
    '&.Mui-checked': {
      color: green[500],
    },
    '&.Mui-checked+.MuiSwitch-track': {
      backgroundColor: green[400],
    },
  },
  track: {
    backgroundColor: red[400],
  },
}));
const useStyles = makeStyles((theme) => ({
  root: {
    float: 'left',
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
}));

interface RedGreenCheckboxProps {
  source: string;
}

const RedGreenCheckbox: FC<RedGreenCheckboxProps> = ({ source }) => {
  const classes = useStyles();
  const switchProps = { classes: useSwitchStyles() };

  function getLabel(formData: any): string {
    return (_.get(formData, source) ? 'Include' : 'Exclude') + ' by default';
  }

  return (
    <FormDataConsumer>
      {({ formData }: any) => (
        <BooleanInput
          source={source}
          label={getLabel(formData)}
          options={switchProps}
          classes={classes}
        />
      )}
    </FormDataConsumer>
  );
};
export default RedGreenCheckbox;
