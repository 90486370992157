import * as React from 'react';
import { useState, MouseEvent } from 'react';
import {
  List,
  Datagrid,
  ResourceComponentProps,
  NumberField,
  TextField,
  BooleanField,
  Filter,
  NumberInput,
  TextInput,
  DateField,
  NullableBooleanInput,
  SelectInput,
  AutocompleteInput,
  EditButton,
  useRefresh,
  useRedirect,
} from 'react-admin';
import CreativePreviewField from './CreativePreviewField';
import { siteGroupIdChoices, siteIdChoices } from '../model-types';
import { bannerPlacementChoices, platformChoices } from './field-choices';
import { countryChoices } from '../countries';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { httpClient } from '../httpClient';

const CountriesField = ({ record }: any) => {
  if (!record.countries || !record.countries?.length) {
    return <span>All</span>;
  }

  const text = record?.countries?.slice(0, 5)?.join(', ');

  return (
    <span>
      {text}
      {record?.countries?.length > 5 && '...'}
    </span>
  );
};

const SiteGroupField = ({ record }: any) => (
  <span>{record.site_group_ids?.join(', ')}</span>
);

const SitsField = ({ record }: any) => <span>{record.sites?.join(', ')}</span>;

const PlatformsField = ({ record }: any) => {
  const text =
    null !== record.platforms && record.platforms.length > 0
      ? record.platforms?.join(', ')
      : 'All';

  return <span>{text}</span>;
};

const CreativeActions = ({ record }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const refresh = useRefresh();
  const redirect = useRedirect();
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickDelete = () => {
    handleClose();

    httpClient.delete('/ads-banners/' + record.id).then(() => {
      refresh();
    });
  };

  const handleClickDeactivate = () => {
    handleClose();

    httpClient
      .patch('/ads-banners/' + record.id, {
        is_active: false,
      })
      .then(() => {
        refresh();
      });
  };

  const handleClickDuplicate = () => {
    handleClose();

    httpClient
      .get('/ads-banners/duplicate/' + record.id)
      .then((response: any) => {
        redirect('/ads-banners/' + response.data.id);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="actions-button"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickDuplicate}>Duplicate</MenuItem>
        <MenuItem onClick={handleClickDeactivate}>Deactivate</MenuItem>
        <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
};

const CreativeFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" alwaysOn />
    <NullableBooleanInput label="Is active" source="is_active" alwaysOn />
    <TextInput label="Type" source="file_extension" alwaysOn />
    <SelectInput
      label="Placement"
      source="banners.placement"
      choices={bannerPlacementChoices}
      alwaysOn
    />
    <AutocompleteInput
      label="Country"
      source="banners.countries"
      choices={countryChoices}
      alwaysOn
    />
    <AutocompleteInput
      label="Platform"
      source="banners.platforms"
      choices={platformChoices}
      alwaysOn
    />
    <SelectInput
      label="Site group"
      source="banners.group.site_group_ids"
      choices={siteGroupIdChoices}
      alwaysOn
    />
    <SelectInput
      label="Site"
      source="banners.sites"
      choices={siteIdChoices}
      alwaysOn
    />
  </Filter>
);

const BannerList = (props: ResourceComponentProps) => {
  return (
    <List
      filters={<CreativeFilter />}
      {...props}
      sort={{ field: 'created_at', order: 'DESC' }}
      filterDefaultValues={{ banners: { is_native: false }, is_active: true }}
    >
      <Datagrid
        optimized
        rowClick={() => {
          return '';
        }}
      >
        <NumberField source="id" />
        <BooleanField source="is_active" />
        <TextField source="type" sortable={false} />
        <CreativePreviewField source="creative" />
        <TextField source="placement" sortable={false} />
        <CountriesField source="countries" sortable={false} />
        <PlatformsField source="platforms" sortable={false} />
        <SiteGroupField source="site_group_ids" sortable={false} />
        <SitsField source="sites" sortable={false} />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <EditButton />
        <CreativeActions />
      </Datagrid>
    </List>
  );
};

export default BannerList;
