import * as React from 'react';
import { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import SitesConfigForm from './SitesConfigForm';

const SitesConfigCreate: FC<ResourceComponentProps> = ({ ...props }) => {
  return (
    <Create title="Create site config" {...props}>
      <SitesConfigForm />
    </Create>
  );
};

export default SitesConfigCreate;
