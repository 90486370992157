import WebpushTplIcon from '@material-ui/icons/Message';

import WebpushTplList from './WebpushTplList';
import WebpushTplEdit from './WebpushTplEdit';
import WebpushTplCreate from './WebpushTplCreate';

export default {
  list: WebpushTplList,
  edit: WebpushTplEdit,
  create: WebpushTplCreate,
  icon: WebpushTplIcon,
};
