import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { TdsPath } from '../../model-types';
import TdsPathForm from './TdsPathForm';

interface PartnerProps {
  record?: TdsPath;
}

const TdsPathTitle: FC<PartnerProps> = ({ record }) =>
  record ? <span>TdsPath #{record.id}</span> : null;

const TdsPathEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<TdsPathTitle />} {...props}>
      <TdsPathForm />
    </Edit>
  );
};

export default TdsPathEdit;
