import React, { useState, MouseEvent } from 'react';
import { useRefresh, useRedirect } from 'react-admin';
import { httpClient } from 'httpClient';

import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { PackageInfoRecord } from 'types/packages/packages';

export const ActionsBtn = ({
  record,
}: {
  label: string;
  record?: PackageInfoRecord;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const refresh = useRefresh();
  const redirect = useRedirect();

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDelete = () => {
    handleClose();

    httpClient.delete('/package-info/' + record?.id).then(() => {
      refresh();
    });
  };

  const handleClickDuplicate = () => {
    handleClose();

    redirect(
      '/package-info/create',
      '',
      undefined,
      {},
      { duplicatePackageInfoId: record?.id },
    );
  };

  return (
    <div>
      <IconButton
        id="actions-button"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickDuplicate}>Duplicate</MenuItem>
        <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
      </Menu>
    </div>
  );
};
