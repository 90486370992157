import CampaignsIcon from '@material-ui/icons/CardTravel';
import CampaignsList from './CampaignsList';
import CampaignsEdit from './CampaignsEdit';
import CampaignsCreate from './CampaignsCreate';

export default {
  list: CampaignsList,
  edit: CampaignsEdit,
  create: CampaignsCreate,
  icon: CampaignsIcon,
};
