import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  Filter,
  NumberInput,
  SelectInput,
  ResourceComponentProps,
} from 'react-admin';
import { hostsChoices, directionChoices } from './field-choices';

const TdsPathFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" alwaysOn />
    <SelectInput source="host" choices={hostsChoices} alwaysOn />
    <SelectInput source="direction" choices={directionChoices} alwaysOn />
  </Filter>
);

const TdsPathList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<TdsPathFilter />}
      perPage={25}
      sort={{ field: 'updated_at', order: 'DESC' }}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="host" />
        <TextField source="direction" />
        <NumberField source="landing_number" />
        <TextField source="destination_path" />
        <DateField source="created_at" locales="ru-RU" showTime />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};

export default TdsPathList;
