import GroupIcon from '@material-ui/icons/Group';

import GroupList from './GroupList';
import GroupEdit from 'userTransfer/group/GroupEdit';
import GroupCreate from 'userTransfer/group/GroupCreate';

export default {
  list: GroupList,
  edit: GroupEdit,
  create: GroupCreate,
  icon: GroupIcon,
};
