import React, { FC } from 'react';
import {
  SelectInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  SelectArrayInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { countryChoicesAllWithoutId } from 'countries';
import { LegalInfoProps } from 'types/sites/legalInfo';

const useStyles = makeStyles(() => ({
  legalInfo: {
    '&>ul>li>section>.ra-input': {
      width: '300px',
      display: 'inline-block',
      '& > div': {
        width: '280px',
      },
    },
  },
}));

export const LegalInfo: FC<LegalInfoProps> = React.memo(
  ({ legalCompanyChoices, isEdit, record }) => {
    const classes = useStyles();

    if (!legalCompanyChoices.length || (isEdit && !record?.legal_info)) {
      return (
        <div>
          <CircularProgress color="inherit" />
        </div>
      );
    }

    return (
      <ArrayInput
        source="legal_info"
        className={classes.legalInfo}
        validate={required()}
        defaultValue={record?.legal_info}
      >
        <SimpleFormIterator disableReordering>
          <SelectInput
            label="Legal company"
            source="company"
            choices={legalCompanyChoices}
            validate={required()}
          />
          <SelectArrayInput
            source="pivot.geo"
            label="Geo"
            choices={countryChoicesAllWithoutId}
            validate={required()}
          />
        </SimpleFormIterator>
      </ArrayInput>
    );
  },
);
