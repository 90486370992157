import React, { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import CampaignsForm from './CampaignsForm';

const CampaignsCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Text Category" {...props}>
      <CampaignsForm />
    </Create>
  );
};
export default CampaignsCreate;
