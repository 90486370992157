import * as React from 'react';
import { FC } from 'react';
import { Create, ResourceComponentProps } from 'react-admin';
import MailDomainsForm from './MailDomainsForm';

const MailDomainsCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Partner" {...props}>
      <MailDomainsForm />
    </Create>
  );
};

export default MailDomainsCreate;
