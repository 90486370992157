import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { TransferGroup } from 'model-types';
import GroupForm from 'userTransfer/group/GroupForm';

interface GroupProps {
  record?: TransferGroup;
}

const GroupTitle: FC<GroupProps> = ({ record }) =>
  record ? <span>Group #{record.id}</span> : null;

const GroupEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<GroupTitle />} {...props}>
      <GroupForm />
    </Edit>
  );
};
export default GroupEdit;
