import affiliateNetworkIcon from '@material-ui/icons/BlurOn';
import AffiliateNetworkList from './AffiliateNetworkList';
import AffiliateNetworkEdit from './AffiliateNetworkEdit';
import AffiliateNetworkCreate from './AffiliateNetworkCreate';

export default {
  list: AffiliateNetworkList,
  edit: AffiliateNetworkEdit,
  create: AffiliateNetworkCreate,
  icon: affiliateNetworkIcon,
};
