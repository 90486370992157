import React, { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import PackageInfoForm from './PackageInfoForm';

const PackageInfoEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit {...props}>
      <PackageInfoForm isEdit />
    </Edit>
  );
};
export default PackageInfoEdit;
