import TdsPathIcon from '@material-ui/icons/CallMissedOutgoing';

import TdsPathList from './TdsPathList';
import TdsPathEdit from './TdsPathEdit';
import TdsPathCreate from './TdsPathCreate';

export default {
  list: TdsPathList,
  edit: TdsPathEdit,
  create: TdsPathCreate,
  icon: TdsPathIcon,
};
