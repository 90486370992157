import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { TransferOffer } from 'model-types';
import OfferForm from './OfferForm';

interface OfferProps {
  record?: TransferOffer;
}

const OfferTitle: FC<OfferProps> = ({ record }) =>
  record ? <span>Offer #{record.id}</span> : null;

const OfferEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<OfferTitle />} {...props}>
      <OfferForm />
    </Edit>
  );
};

export default OfferEdit;
