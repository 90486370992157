import * as React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { TransferGeoSegment } from 'model-types';
import GeoSegmentForm from './GeoSegmentForm';

interface GeoSegmentProps {
  record?: TransferGeoSegment;
}

const GeoSegmentTitle: FC<GeoSegmentProps> = ({ record }) =>
  record ? <span>Geo Segment #{record.id}</span> : null;

const GeoSegmentEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<GeoSegmentTitle />} {...props}>
      <GeoSegmentForm />
    </Edit>
  );
};
export default GeoSegmentEdit;
