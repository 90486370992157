import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  BooleanInput,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

import { ChoicesApi } from 'api/ChoicesApi';

import { Package } from 'types/packages/packages';
import { IChoice } from 'types/global';

interface PackagesProps {
  record?: Package;
}

const PackagesForm: FC<PackagesProps> = ({ record, ...rest }) => {
  const [typeChoices, setTypeChoices] = useState<IChoice[]>([]);
  const [productTypeChoices, setProductTypeChoices] = useState<IChoice[]>([]);
  const [packageScopeChoices, setPackageScopeChoices] = useState<IChoice[]>([]);
  const [productSubtypeChoices, setProductSubtypeChoices] = useState<IChoice[]>(
    [],
  );
  const [transactionTypeChoices, setTransactionTypeChoices] = useState<
    IChoice[]
  >([]);

  const setChoices = useCallback(async () => {
    const scope = await ChoicesApi.fetchPackageScopeChoices();
    const type = await ChoicesApi.fetchPackageTypeChoices();
    const typeProduct = await ChoicesApi.fetchProductTypeChoices();
    const productSubtype = await ChoicesApi.fetchProductSubtypeChoices();
    const transactionType = await ChoicesApi.fetchTransactionTypeChoices();

    setPackageScopeChoices(scope);
    setTypeChoices(type);
    setProductTypeChoices(typeProduct);
    setProductSubtypeChoices(productSubtype);
    setTransactionTypeChoices(transactionType);
  }, []);

  useEffect(() => {
    setChoices();
  }, []);

  if (!record) return null;

  return (
    <SimpleForm {...rest} redirect="list">
      <div style={{ width: '50%' }}>
        <TextInput
          source="name"
          validate={[required()]}
          style={{ width: '150px', marginRight: '10px' }}
        />
        <SelectInput
          source="scope"
          choices={packageScopeChoices}
          loading={packageScopeChoices.length === 0}
          validate={[required()]}
          style={{ width: '150px', marginRight: '10px' }}
        />
        <SelectInput
          source="transaction_type"
          choices={transactionTypeChoices}
          loading={transactionTypeChoices.length === 0}
          validate={[required()]}
          style={{ width: '150px', marginRight: '10px' }}
        />
        <SelectInput
          source="type"
          choices={typeChoices}
          loading={typeChoices.length === 0}
          validate={[required()]}
          style={{ width: '150px', marginRight: '10px' }}
        />
        <SelectInput
          source="product_type"
          choices={productTypeChoices}
          loading={productTypeChoices.length === 0}
          validate={[required()]}
          style={{ width: '150px', marginRight: '10px' }}
        />
        <SelectInput
          source="product_subtype"
          choices={productSubtypeChoices}
          loading={productSubtypeChoices.length === 0}
          validate={[required()]}
          style={{ width: '150px' }}
        />
      </div>
      <div style={{ width: '50%' }}>
        <NumberInput
          source="credits_buy"
          validate={[required()]}
          style={{ width: '150px', marginRight: '10px' }}
        />
        <NumberInput
          source="duration"
          validate={[required()]}
          style={{ width: '150px', marginRight: '10px' }}
        />
        <NumberInput
          source="moneyback_duration"
          validate={[required()]}
          style={{ width: '150px', marginRight: '10px' }}
        />
      </div>
      <BooleanInput source="is_free" defaultValue={false} />
      <BooleanInput source="is_refund" defaultValue={false} />
      <BooleanInput source="is_trial" defaultValue={false} />
    </SimpleForm>
  );
};

export default PackagesForm;
