import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { MenuItemLink } from 'react-admin';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useMediaQuery, Theme } from '@material-ui/core';
import PhotoModerationIcon from '@material-ui/icons/PhotoCamera';
import VideoModerationIcon from '@material-ui/icons/Videocam';
import DocsIcon from '@material-ui/icons/Subject';
import EnumIcon from '@material-ui/icons/List';
import SystemSettingsIcon from '@material-ui/icons/Settings';
import SiteConfigIcon from '@material-ui/icons/SettingsApplications';
import LegalInfoIcon from '@material-ui/icons/Assignment';
import MailDomainsIcon from '@material-ui/icons/Mail';

import adsBanners from 'adsBanners';
import webpushTpl from 'webpush/templates';
import partner from 'partner';
import partnerNetwork from 'partner/network';
import transferOffer from 'userTransfer/offer';
import transferGroup from 'userTransfer/group';
import transferBrand from 'userTransfer/brand';
import affiliateNetwork from 'userTransfer/affiliateNetwork';
import geoSegment from 'userTransfer/geoSegment';
import marketingFlow from 'tds/marketingFlow';
import tdsPath from 'tds/tdsPath';
import users from 'users';
import textCategory from 'translations/textCategory';
import textSource from 'translations/textSource';
import packages from 'pages/packages';
import campaigns from 'pages/campaigns';

import SubMenu from './SubMenu';
import { Count } from './Count';

import { Api } from 'api/Api';
import { isGranted } from 'authProvider';
import { EventEmitter } from 'eventEmitter';
import { getLocalStorageItem } from 'helper/localStorage';
import notificationSound from 'assets/audio/notification.mp3';

import { AppState } from 'types';
import { EmitterEvents } from 'types/enums/EmitterEvents';

interface ModerationCounters {
  photosModerationCounter: number;
  botPhotosModerationCounter: number;
  videosModerationCounter: number;
}

type MenuName =
  | 'menuPartners'
  | 'menuTds'
  | 'menuTransfer'
  | 'menuTranslations'
  | 'menuDocs'
  | 'siteConfig'
  | 'packages';

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const COUNTERS_RELOAD_INTERVAL = 20 * 1000;

const DEFAULT_MODERATION_COUNTERS = {
  photosModerationCounter: 0,
  botPhotosModerationCounter: 0,
  videosModerationCounter: 0,
};

const audio = new Audio(notificationSound);

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const { pathname } = useLocation();

  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const isAddedNewModerationItem = useRef<boolean>(false);
  const isInitLoad = useRef<boolean>(true);

  const [moderationCounters, setModerationCounters] =
    useState<ModerationCounters>(DEFAULT_MODERATION_COUNTERS);

  const [activePage, setActivePage] = useState({
    menuPartners: pathname.startsWith('/partners'),
    menuTds: pathname.startsWith('/tds/marketing-flows'),
    menuTransfer: pathname.startsWith('/user-transfers/'),
    menuTranslations: pathname.startsWith('/translations/'),
    menuDocs: pathname.startsWith('/docs/'),
    siteConfig: pathname.startsWith('/sites-config'),
    packages: pathname.startsWith('/packages'),
  });
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );
  const isOpenSidebar = useSelector(
    (state: AppState) => state.admin.ui.sidebarOpen,
  );
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = useCallback((menu: MenuName) => {
    setActivePage((prevActivePage) => ({
      ...prevActivePage,
      [menu]: !prevActivePage[menu],
    }));
  }, []);

  const loadModerationCounters = useCallback(async () => {
    const countersResponse = await Api.fetchModerationCounters();

    setModerationCounters((prevCounters) => {
      const newCounters = {
        photosModerationCounter: countersResponse.photo_moderation_counter,
        botPhotosModerationCounter:
          countersResponse.bot_photo_moderation_counter,
        videosModerationCounter: countersResponse.video_moderation_counter,
      };

      if (
        (newCounters.photosModerationCounter >
          prevCounters.photosModerationCounter ||
          newCounters.botPhotosModerationCounter >
            prevCounters.botPhotosModerationCounter ||
          newCounters.videosModerationCounter >
            prevCounters.videosModerationCounter) &&
        !isInitLoad.current
      ) {
        isAddedNewModerationItem.current = true;
      }

      if (isInitLoad.current) isInitLoad.current = false;

      return newCounters;
    });

    timeoutId.current = setTimeout(
      loadModerationCounters,
      COUNTERS_RELOAD_INTERVAL,
    );
  }, []);

  useEffect(() => {
    if (!isAddedNewModerationItem.current || !moderationCounters) return;

    isAddedNewModerationItem.current = false;

    if (
      pathname === '/photo/moderation/user' ||
      pathname === '/photo/moderation/bot' ||
      pathname === '/video/moderation'
    ) {
      EventEmitter.dispatch(EmitterEvents.PhotoModerationReload);
    }

    if (getLocalStorageItem('sound')) audio?.play()?.catch(() => {});
  }, [moderationCounters, pathname]);

  useEffect(() => {
    loadModerationCounters();

    EventEmitter.subscribe(EmitterEvents.ReloadModerationCounters, () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      loadModerationCounters();
    });

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      EventEmitter.unsubscribe(EmitterEvents.ReloadModerationCounters);
    };
  }, [loadModerationCounters]);

  return (
    <div>
      {' '}
      {isGranted(['moderator', 'admin']) && (
        <MenuItemLink
          leftIcon={
            <Count count={moderationCounters.photosModerationCounter}>
              <PhotoModerationIcon />
            </Count>
          }
          to={`/photo/moderation/user`}
          primaryText="User Photo moderation"
          onClick={onMenuClick}
          sidebarIsOpen={isOpenSidebar}
          dense={dense}
        />
      )}
      {isGranted(['moderator', 'admin']) && (
        <MenuItemLink
          leftIcon={
            <Count count={moderationCounters.botPhotosModerationCounter}>
              <PhotoModerationIcon />
            </Count>
          }
          to={`/photo/moderation/bot`}
          primaryText="Bot Photo moderation"
          onClick={onMenuClick}
          sidebarIsOpen={isOpenSidebar}
          dense={dense}
        />
      )}
      {isGranted(['moderator', 'admin']) && (
        <MenuItemLink
          leftIcon={
            <Count count={moderationCounters.videosModerationCounter}>
              <VideoModerationIcon />
            </Count>
          }
          to={`/video/moderation`}
          primaryText="Video moderation"
          onClick={onMenuClick}
          sidebarIsOpen={isOpenSidebar}
          dense={dense}
        />
      )}
      {isGranted(['support', 'admin']) && (
        <MenuItemLink
          leftIcon={<users.icon />}
          to={`/users`}
          primaryText="Users"
          onClick={onMenuClick}
          sidebarIsOpen={isOpenSidebar}
          dense={dense}
        />
      )}
      {isGranted(['admin']) && (
        <MenuItemLink
          leftIcon={<webpushTpl.icon />}
          to={`/webpush/templates`}
          primaryText="Webpush templates"
          onClick={onMenuClick}
          sidebarIsOpen={isOpenSidebar}
          dense={dense}
        />
      )}
      {isGranted(['admin']) && (
        <SubMenu
          handleToggle={() => handleToggle('menuPartners')}
          isOpen={activePage.menuPartners}
          sidebarIsOpen={isOpenSidebar}
          name="Partners"
          icon={<partner.icon />}
          dense={dense}
        >
          <MenuItemLink
            leftIcon={<partner.icon />}
            to={`/partners`}
            primaryText="Partners"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
          <MenuItemLink
            to={`/partners-networks`}
            primaryText="Partner networks"
            leftIcon={<partnerNetwork.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
        </SubMenu>
      )}
      {isGranted(['admin']) && (
        <SubMenu
          handleToggle={() => handleToggle('menuTds')}
          isOpen={activePage.menuTds}
          sidebarIsOpen={isOpenSidebar}
          name="TDS"
          icon={<marketingFlow.icon />}
          dense={dense}
        >
          <MenuItemLink
            leftIcon={<marketingFlow.icon />}
            to={`/tds/marketing-flows`}
            primaryText="Marketing Flows"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
          <MenuItemLink
            to={`/tds/tds-paths`}
            primaryText="Tds Paths"
            leftIcon={<tdsPath.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
        </SubMenu>
      )}
      {isGranted(['admin']) && (
        <SubMenu
          handleToggle={() => handleToggle('menuTransfer')}
          isOpen={activePage.menuTransfer}
          sidebarIsOpen={isOpenSidebar}
          name="Transfer"
          icon={<transferOffer.icon />}
          dense={dense}
        >
          <MenuItemLink
            leftIcon={<transferOffer.icon />}
            to={`/user-transfers/offers`}
            primaryText="Offers"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
          <MenuItemLink
            to={`/user-transfers/groups`}
            primaryText="Groups"
            leftIcon={<transferGroup.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
          <MenuItemLink
            to={`/user-transfers/brands`}
            primaryText="Brands"
            leftIcon={<transferBrand.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
          <MenuItemLink
            to={`/user-transfers/affiliate-networks`}
            primaryText="Affiliate Networks"
            leftIcon={<affiliateNetwork.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
          <MenuItemLink
            to={`/user-transfers/geo-segments`}
            primaryText="Geo segments"
            leftIcon={<geoSegment.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
        </SubMenu>
      )}
      {isGranted(['chatter']) && (
        <MenuItemLink
          leftIcon={<partner.icon />}
          to={`/chats`}
          primaryText="Chats"
          onClick={onMenuClick}
          sidebarIsOpen={isOpenSidebar}
          dense={dense}
        />
      )}
      {isGranted(['admin']) && (
        <SubMenu
          handleToggle={() => handleToggle('menuTranslations')}
          isOpen={activePage.menuTranslations}
          sidebarIsOpen={isOpenSidebar}
          name="Texts"
          icon={<textSource.icon />}
          dense={dense}
        >
          <MenuItemLink
            leftIcon={<textSource.icon />}
            to={`/translations/text-sources`}
            primaryText="Text Sources"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
          <MenuItemLink
            leftIcon={<textCategory.icon />}
            to={`/translations/text-categories`}
            primaryText="Text Categories"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
        </SubMenu>
      )}
      {isGranted(['admin']) && (
        <MenuItemLink
          leftIcon={<SystemSettingsIcon />}
          to={`/system-settings`}
          primaryText="System settings"
          onClick={onMenuClick}
          sidebarIsOpen={isOpenSidebar}
          dense={dense}
        />
      )}
      {isGranted(['admin']) && (
        <SubMenu
          handleToggle={() => handleToggle('siteConfig')}
          isOpen={activePage.siteConfig}
          sidebarIsOpen={isOpenSidebar}
          name="Sites config"
          icon={<SiteConfigIcon />}
          dense={dense}
        >
          <MenuItemLink
            leftIcon={<SiteConfigIcon />}
            to={`/sites`}
            primaryText="Sites config"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
          <MenuItemLink
            leftIcon={<LegalInfoIcon />}
            to={`/legal-info`}
            primaryText="Legal info"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
          <MenuItemLink
            leftIcon={<MailDomainsIcon />}
            to={`/mail-domain`}
            primaryText="Mail domain"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
        </SubMenu>
      )}
      {isGranted(['admin']) && (
        <SubMenu
          handleToggle={() => handleToggle('menuDocs')}
          isOpen={activePage.menuDocs}
          sidebarIsOpen={isOpenSidebar}
          name="Docs"
          icon={<DocsIcon />}
          dense={dense}
        >
          <MenuItemLink
            leftIcon={<EnumIcon />}
            to={`/docs/enums`}
            primaryText="Enums"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
        </SubMenu>
      )}
      {isGranted(['admin']) && (
        <MenuItemLink
          leftIcon={<adsBanners.icon />}
          to={`/ads-banners`}
          primaryText="Ads banners"
          onClick={onMenuClick}
          sidebarIsOpen={isOpenSidebar}
          dense={dense}
        />
      )}
      {isGranted(['admin']) && (
        <SubMenu
          handleToggle={() => handleToggle('packages')}
          isOpen={activePage.packages}
          sidebarIsOpen={isOpenSidebar}
          name="Packages"
          icon={<packages.icon />}
          dense={dense}
        >
          <MenuItemLink
            leftIcon={<packages.icon />}
            to={`/packages`}
            primaryText="Packages"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
          <MenuItemLink
            leftIcon={<campaigns.icon />}
            to={`/campaigns`}
            primaryText="Campaigns"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          />
          {/* <MenuItemLink
            leftIcon={<campaigns.icon />}
            to={`/package-info`}
            primaryText="Package info"
            onClick={onMenuClick}
            sidebarIsOpen={isOpenSidebar}
            dense={dense}
          /> */}
        </SubMenu>
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
