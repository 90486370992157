import React, { useCallback, useEffect, useState } from 'react';
import {
  Datagrid,
  List,
  TextField,
  ResourceComponentProps,
  BooleanField,
  Filter,
  SelectInput,
  SelectField,
  TextInput,
} from 'react-admin';

import { ChoicesApi } from 'api/ChoicesApi';

import Loader from 'layout/Loader';

import { IChoice } from 'types/global';

const PackagesFilter = ({
  typeChoices,
  productTypeChoices,
  productSubtypeChoices,
  ...props
}: {
  typeChoices: IChoice[];
  productTypeChoices: IChoice[];
  productSubtypeChoices: IChoice[];
}) => {
  return (
    <Filter {...props}>
      <TextInput source="id" label="Id" style={{ width: '120px' }} alwaysOn />
      <TextInput
        source="name"
        label="Name"
        style={{ width: '120px' }}
        alwaysOn
      />
      <SelectInput
        source="product_type"
        choices={productTypeChoices}
        loading={productTypeChoices.length === 0}
        style={{ width: '120px' }}
        alwaysOn
      />
      <SelectInput
        source="product_subtype"
        choices={productSubtypeChoices}
        loading={productSubtypeChoices.length === 0}
        style={{ width: '140px' }}
        alwaysOn
      />
      <SelectInput
        source="type"
        choices={typeChoices}
        loading={typeChoices.length === 0}
        style={{ width: '120px' }}
        alwaysOn
      />
    </Filter>
  );
};

const PackagesList = (props: ResourceComponentProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const [typeChoices, setTypeChoices] = useState<IChoice[]>([]);
  const [productTypeChoices, setProductTypeChoices] = useState<IChoice[]>([]);
  const [productSubtypeChoices, setProductSubtypeChoices] = useState<IChoice[]>(
    [],
  );

  const setChoices = useCallback(async () => {
    const packageType = await ChoicesApi.fetchPackageTypeChoices();
    const productType = await ChoicesApi.fetchProductTypeChoices();
    const productSubtype = await ChoicesApi.fetchProductSubtypeChoices();

    setTypeChoices(packageType);
    setProductTypeChoices(productType);
    setProductSubtypeChoices(productSubtype);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    setChoices();
  }, []);

  return (
    <List
      {...props}
      filters={
        <PackagesFilter
          typeChoices={typeChoices}
          productTypeChoices={productTypeChoices}
          productSubtypeChoices={productSubtypeChoices}
        />
      }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Datagrid optimized rowClick="edit">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="credits_buy" />
          <TextField source="duration" />
          <TextField source="moneyback_duration" />
          <SelectField source="product_type" choices={productTypeChoices} />
          <SelectField
            source="product_subtype"
            choices={productSubtypeChoices}
          />
          <TextField source="scope" />
          <TextField source="transaction_type" />
          <TextField source="type" />
          <BooleanField source="is_free" />
          <BooleanField source="is_refund" />
          <BooleanField source="is_trial" />
        </Datagrid>
      )}
    </List>
  );
};

export default PackagesList;
